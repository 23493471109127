import { CardHeader, CardTitle } from "~/components/ui/card";
import { DocumentType } from "~/api/query_fns/documents";
import { format } from "date-fns";
import ClaimTag from "./ClaimTag";
import { Badge } from "~/components/ui/badge";

const DocumentCard = ({ doc }: { doc: DocumentType }) => {
  const dateObj = new Date(doc.createdAt);
  const formattedDate = format(dateObj, "MMM d, yyyy");

  return (
    <div className="group relative rounded-lg border border-border/60 bg-card p-5 shadow-[0_2px_8px_rgba(0,0,0,0.08)] transition-all duration-200 hover:scale-[1.002] hover:border-border hover:bg-accent/5 hover:shadow-[0_4px_12px_rgba(0,0,0,0.12)]">
      <CardHeader className="space-y-3 p-0">
        <div className="flex items-center justify-between">
          <CardTitle className="text-lg font-medium group-hover:text-primary">
            {doc.filename}
          </CardTitle>
          <span className="text-sm text-muted-foreground">{formattedDate}</span>
        </div>

        <div className="flex flex-wrap gap-1.5">
          {doc.claimNumber && (
            <ClaimTag color={doc.claimColor} number={doc.claimNumber} />
          )}
          <Badge
            variant="secondary"
            className="bg-secondary/80 text-secondary-foreground"
          >
            {doc.uploaderEmail}
          </Badge>
        </div>
      </CardHeader>
    </div>
  );
};

export default DocumentCard;
