import { useState } from "react";
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom";
import { connectSearchBox } from "react-instantsearch-dom";
import { createTypesenseSearchClient } from "../utils/CreateTypesenseSearchClient";
import { CardHeader, CardTitle, CardDescription } from "~/components/ui/card";
import { format } from "date-fns";
import { Input } from "~/components/ui/input";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Paragraph } from "~/components/ui/paragraph";
import { getDocument } from "~/api";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { SearchBoxProvided } from "react-instantsearch-core";

interface HitsProps {
  hits: Array<any>;
  showPreview: boolean;
  onDocumentSelect: (doc: any) => void;
}

const CustomHits: React.FC<HitsProps> = ({
  hits,
  showPreview,
  onDocumentSelect,
}) => (
  <>
    {showPreview && (
      <div className="hits-wrapper h-[calc(35vh-200px)] overflow-y-auto pl-8 pr-8">
        {hits.length > 0 ? (
          <div className="space-y-4 p-4">
            {hits.map((hit) => (
              <Hit
                key={hit.objectID}
                hit={hit}
                onDocumentSelect={onDocumentSelect}
              />
            ))}
          </div>
        ) : (
          <Paragraph variant="small" className="my-5 p-4 text-muted-foreground">
            No results.
          </Paragraph>
        )}
      </div>
    )}
  </>
);

interface HitProps {
  hit: {
    id: string;
    filename: string;
    pages: string;
    userEmail: string;
    createdAt: number;
    claimNumber?: string;
    documents?: Array<{ id: string; filename: string }>;
  };
  onDocumentSelect: (doc: any) => void;
}

const Hit: React.FC<HitProps> = ({ hit, onDocumentSelect }) => {
  const formattedDate = format(hit.createdAt * 1000, "MMM d, yyyy");

  const handleClick = async () => {
    console.log("Hit ID:", hit.id);
    if (hit.id) {
      try {
        const doc = await getDocument({ id: hit.id || "" });
        onDocumentSelect(doc);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    }
  };

  return (
    <div className="custom-shadcn-result-card" onClick={handleClick}>
      <CardHeader>
        <CardTitle className="group-hover:underline">{hit.filename}</CardTitle>
        <CardDescription>
          {formattedDate} &bull; {hit.userEmail || ""}
        </CardDescription>
      </CardHeader>
    </div>
  );
};

const CustomSearchBox = ({
  currentRefinement,
  refine,
  setShowPreview,
}: any) => (
  <form noValidate action="" role="search" className="mb-4">
    <div className="flex w-full items-center rounded-lg pl-8 pr-8 pt-8">
      <div className="flex-grow">
        <div className="relative">
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-6 w-6 -translate-y-1/2 transform text-gray-500" />
          <Input
            type="search"
            className="pl-11 pr-8"
            value={currentRefinement}
            onChange={(event) => {
              refine(event.currentTarget.value);
              setShowPreview(event.currentTarget.value.length > 0);
            }}
            placeholder="Search file ..."
          />
        </div>
      </div>
    </div>
  </form>
);

const ConnectedSearchBox = connectSearchBox<
  SearchBoxProvided & { setShowPreview: (show: boolean) => void }
>(({ setShowPreview, ...props }) => (
  <CustomSearchBox
    currentRefinement={props.currentRefinement}
    refine={props.refine}
    setShowPreview={setShowPreview}
  />
));

interface JobReportFileSearchProps {
  file_types: string[];
  itemsPerPage: number;
  uploudFileFromList: (doc: SingleDocResponseData) => void;
}

const JobReportFileSearch: React.FC<JobReportFileSearchProps> = ({
  file_types = [],
  itemsPerPage,
  uploudFileFromList,
}) => {
  const searchClient = createTypesenseSearchClient("documents");
  const ConnectedHits = connectHits(({ hits }) => (
    <CustomHits
      hits={hits}
      showPreview={false}
      onDocumentSelect={handleDocumentSelect}
    />
  ));

  // const ConnectedPagination = connectPagination(CustomPagination);
  const [_showPreview, setShowPreview] = useState(false);

  const handleDocumentSelect = (doc: any) => {
    uploudFileFromList(doc);
  };

  return (
    <InstantSearch searchClient={searchClient} indexName="documents">
      <Configure
        query_by="filename"
        filter_by={`document_type:[${file_types.join("|")} || '']`}
        facet_by="document_type,user_id,organization_id"
        per_page={itemsPerPage}
      />
      <ConnectedSearchBox setShowPreview={setShowPreview} />
      <ConnectedHits />
    </InstantSearch>
  );
};

export default JobReportFileSearch;
