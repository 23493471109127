import { nanoid } from "nanoid";
import { useRef, useState } from "react";
import { Message, OnResponseCompleteCallback } from "../../CustomMessages";
import {
  ToolDefinition,
  FunctionCallHandler,
  COPILOT_CLOUD_PUBLIC_API_KEY_HEADER,
} from "../copilotkit-utils";

import { v4 as uuidv4 } from "uuid";
import { CopilotApiConfig } from "@copilotkit/react-core";
import { fetchAndDecodeChatCompletion } from "../fetch-chat-completion";

export type UseChatOptions = {
  /**
   * The API endpoint that accepts a `{ messages: Message[] }` object and returns
   * a stream of tokens of the AI chat response. Defaults to `/api/chat`.
   */
  api?: string;
  /**
   * A unique identifier for the chat. If not provided, a random one will be
   * generated. When provided, the `useChat` hook with the same `id` will
   * have shared states across components.
   */
  id?: string;
  /**
   * System messages of the chat. Defaults to an empty array.
   */
  initialMessages?: Message[];
  /**
   * Callback function to be called when a function call is received.
   * If the function returns a `ChatRequest` object, the request will be sent
   * automatically to the API and will be used to update the chat.
   */
  onFunctionCall?: React.MutableRefObject<FunctionCallHandler>;
  /**
   * HTTP headers to be sent with the API request.
   */
  headers?: Record<string, string> | Headers;
  /**
   * Extra body object to be sent with the API request.
   * @example
   * Send a `sessionId` to the API along with the messages.
   * ```js
   * useChat({
   *   body: {
   *     sessionId: '123',
   *   }
   * })
   * ```
   */
  body?: object;
  /**
   * Function definitions to be sent to the API.
   */
  tools?: React.MutableRefObject<ToolDefinition[]>;

  chatId?: string;
};

export type UseChatHelpers = {
  /**
   * Append a user message to the chat list. This triggers the API call to fetch
   * the assistant's response.
   * @param message The message to append
   */
  append: (message: Message) => Promise<void>;
  /**
   * Reload the last AI chat response for the given chat history. If the last
   * message isn't from the assistant, it will request the API to generate a
   * new response.
   */
  reload: () => Promise<void>;
  /**
   * Abort the current request immediately, keep the generated tokens if any.
   */
  stop: () => void;
  /**
   * Error message to be displayed to the user.
   */
  error?: string;
  /**
   * Clear the error message.
   */
  clearError: () => void;
};

export type UseChatOptionsWithCopilotConfig = UseChatOptions & {
  copilotConfig: CopilotApiConfig;
  /**
   * The current list of messages in the chat.
   */
  messages: Message[];
  /**
   * The setState-powered method to update the chat messages.
   */
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;

  /**
   * A callback to get the latest system message.
   */
  makeSystemMessageCallback: () => Message;

  /**
   * Whether the API request is in progress
   */
  isLoading: boolean;

  /**
   * setState-powered method to update the isChatLoading value
   */
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onResponseComplete?: OnResponseCompleteCallback;
  messageGenerationCompleteBeforeFinalization?: (
    context: string
  ) => Promise<string>;
  onErrorMessage?: (errorMessage: string) => string;
};

export function useChat(
  options: UseChatOptionsWithCopilotConfig
): UseChatHelpers {
  const [error, setError] = useState<string | undefined>();

  const {
    messages,
    setMessages,
    makeSystemMessageCallback,
    onResponseComplete,
  } = options;
  const abortControllerRef = useRef<AbortController>();
  const threadIdRef = useRef<string | null>(null);
  const runIdRef = useRef<string | null>(null);
  const publicApiKey = options.copilotConfig.publicApiKey;
  const headers = {
    ...(options.headers || {}),
    ...(publicApiKey
      ? { [COPILOT_CLOUD_PUBLIC_API_KEY_HEADER]: publicApiKey }
      : {}),
  };

  const runChatCompletion = async (messages: Message[]): Promise<Message[]> => {
    try {
      setError(undefined);
      options.setIsLoading(true);

      const newMessages: Message[] = [
        {
          id: uuidv4(),
          createdAt: new Date(),
          content: "",
          role: "assistant",
          isVisible: true,
        },
      ];
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      setMessages([...messages, ...newMessages]);
      // add threadId and runId to the body if it exists
      const copilotConfigBody = options.copilotConfig.body || {};
      if (threadIdRef.current) {
        copilotConfigBody.threadId = threadIdRef.current;
      }
      if (runIdRef.current) {
        copilotConfigBody.runId = runIdRef.current;
      }

      const systemMessage = makeSystemMessageCallback();

      const messagesWithContext = [
        systemMessage,
        ...(options.initialMessages || []),
        ...messages,
      ];
      const response = await fetchAndDecodeChatCompletion({
        copilotConfig: { ...options.copilotConfig, body: copilotConfigBody },
        messages: messagesWithContext,
        tools: options.tools?.current,
        headers: headers,
        signal: abortController.signal,
      });

      if (response.headers.get("threadid")) {
        threadIdRef.current = response.headers.get("threadid");
      }

      if (response.headers.get("runid")) {
        runIdRef.current = response.headers.get("runid");
      }

      // Get the last user message that is not a context message
      const currentUserMessage = [...messages]
        .reverse()
        .find((msg) => msg.role === "user");

      // Get the first system message

      // Log the last user message for debugging

      // You can use lastUserMessage here if needed, for example:
      // if (lastUserMessage) {
      //   copilotConfigBody.lastUserMessage = lastUserMessage.content;
      // }

      if (!response.events) {
        setMessages([
          ...messages,
          {
            id: uuidv4(),
            createdAt: new Date(),
            content: response.statusText,
            role: "assistant",
            isVisible: true,
          },
        ]);
        options.setIsLoading(false);
        throw new Error("Failed to fetch chat completion");
      }

      const reader = response.events.getReader();

      // Whether to feed back the new messages to GPT
      let feedback = false;

      try {
        let isDone = false;
        while (!isDone) {
          const { done, value } = await reader.read();
          isDone = done;

          let currentAIMessage = Object.assign(
            {},
            newMessages[newMessages.length - 1]
          );

          if (done) {
            // Apply the questionId and chatId from the last user message to the current message

            if (
              currentAIMessage &&
              options.messageGenerationCompleteBeforeFinalization
            ) {
              try {
                const lastMessage = newMessages[newMessages.length - 1];
                lastMessage.content =
                  await options.messageGenerationCompleteBeforeFinalization(
                    lastMessage.content
                  );
              } catch (error) {
                console.error("Unable to get references:", error);
              }
            }
            let allMessages = [...messages, ...newMessages];
            allMessages = allMessages.map((msg, index) => ({
              ...msg,
              chatId: options.chatId,
              questionId: index > 0 ? allMessages[index - 1].id : undefined,
            }));
            // This approach works, but there might be a more efficient way
            // Consider using a state update function for better performance

            currentAIMessage.questionId = currentUserMessage?.id;

            if (currentUserMessage && currentAIMessage && onResponseComplete) {
              /*        console.log("Thread ID:", response.headers.get("threadid"));
                console.log("First system message:", firstSystemMessage);
                console.log("Last user message:", currentUserMessage);
                console.log("currentMessage  message:", currentAIMessage);
    
                // Log all messages in order
                console.log("All messages in order:");
                console.log("system:", systemMessage); */
              [...allMessages].forEach((msg, index) => {
                console.log(`Message ${index + 1}:`, {
                  role: msg.role,
                  content: msg.content,
                  id: msg.id,
                  questionId: msg.questionId,
                  chatId: msg.chatId,
                });
              });

              onResponseComplete(currentUserMessage, currentAIMessage);
            }

            break;
          }

          if (value.type === "content") {
            if (
              currentAIMessage.function_call ||
              currentAIMessage.role === "function"
            ) {
              // Create a new message if the previous one is a function call or result
              currentAIMessage = {
                id: nanoid(),
                createdAt: new Date(),
                content: "",
                role: "assistant",
                isVisible: true,
              };
              newMessages.push(currentAIMessage);
            }
            currentAIMessage.content += value.content;
            newMessages[newMessages.length - 1] = currentAIMessage;
            setMessages([...messages, ...newMessages]);
          } else if (value.type === "result") {
            // When we get a result message, it is already complete
            currentAIMessage = {
              id: nanoid(),
              role: "function",
              content: value.content,
              name: value.name,
              isVisible: false,
            };
            newMessages.push(currentAIMessage);
            setMessages([...messages, ...newMessages]);

            // After receiving a result, feed back the new messages to GPT
            feedback = true;
          } else if (value.type === "function" || value.type === "partial") {
            // Create a new message if the previous one is not empty
            if (
              currentAIMessage.content != "" ||
              currentAIMessage.function_call ||
              currentAIMessage.role == "function"
            ) {
              currentAIMessage = {
                id: nanoid(),
                createdAt: new Date(),
                content: "",
                role: "assistant",
                isVisible: true,
              };
              newMessages.push(currentAIMessage);
            }
            if (value.type === "function") {
              currentAIMessage.function_call = {
                name: value.name,
                arguments: JSON.stringify(value.arguments),
                scope: value.scope,
              };
            } else if (value.type === "partial") {
              /*             let partialArguments: any = {};
              try {
                partialArguments = JSON.parse(untruncateJson(value.arguments));
              } catch (e) {}

              currentAIMessage.partialFunctionCall = {
                name: value.name,
                arguments: partialArguments,
              }; */
              console.log("partial", value);
            }

            newMessages[newMessages.length - 1] = currentAIMessage;
            setMessages([...messages, ...newMessages]);

            if (value.type === "function") {
              /*             // Execute the function call
              try {
                if (options.onFunctionCall?.current && value.scope === "client") {
                  const result = await options.onFunctionCall.current(
                    messages,
                    currentAIMessage.function_call as FunctionCall
                  );

                  currentAIMessage = {
                    id: nanoid(),
                    role: "function",
                    content: encodeResult(result),
                    name: (currentAIMessage.function_call! as FunctionCall).name!,
                    isVisible: false,
                  };
                  newMessages.push(currentAIMessage);
                  setMessages([...messages, ...newMessages]);

                  // After a function call, feed back the new messages to GPT
                  feedback = true;
                }
              } catch (error) {
                console.error("Failed to execute function call", error);
                // TODO: Handle error
                // this should go to the message itself
              } */
              console.log("function", value);
            }
          }
        }

        // If we want feedback, run the completion again and return the results
        if (feedback) {
          // wait for next tick to make sure all the react state updates
          // TODO: This is a hack, is there a more robust way to do this?
          // - tried using react-dom's flushSync, but it did not work
          await new Promise((resolve) => setTimeout(resolve, 10));

          return await runChatCompletion([...messages, ...newMessages]);
        }
        // otherwise, return the new messages
        else {
          return newMessages.slice();
        }
      } finally {
        options.setIsLoading(false);
      }
    } catch (err) {
      console.error("Raw error:", err);
      const errorMessage =
        err instanceof Error ? err.message : "An unknown error occurred";
      if (errorMessage.includes("signal is aborted without reason")) {
        console.log("error", errorMessage);
        // setError(modErrorMessage);
      } else {
        const modErrorMessage =
          options.onErrorMessage?.(errorMessage) || errorMessage;
        console.log("error", errorMessage);
        setError(modErrorMessage);
      }
      stop();
      return [];
    }
  };

  const runChatCompletionAndHandleFunctionCall = async (
    messages: Message[]
  ): Promise<void> => {
    await runChatCompletion(messages);
  };

  const append = async (message: Message): Promise<void> => {
    if (options.isLoading) {
      return;
    }
    const newMessages = [...messages, message];
    setMessages(newMessages);
    return runChatCompletionAndHandleFunctionCall(newMessages);
  };

  const reload = async (): Promise<void> => {
    if (options.isLoading || messages.length === 0) {
      return;
    }
    setError(undefined);
    let newMessages = [...messages];
    const lastMessage = messages[messages.length - 1];

    if (lastMessage.role === "assistant") {
      newMessages = newMessages.slice(0, -1);
    }
    setMessages(newMessages);

    return runChatCompletionAndHandleFunctionCall(newMessages);
  };

  const stop = (): void => {
    abortControllerRef.current?.abort();
    options.setIsLoading(false);

    // Remove the last assistant message if it's empty, along with the preceding user message
    setMessages((prevMessages) => {
      if (prevMessages.length > 0) {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage.role === "assistant" && !lastMessage.content) {
          // Remove both the empty assistant message and the preceding user message
          return prevMessages.slice(0, -2);
        }
      }
      return prevMessages;
    });
  };

  return {
    append,
    reload,
    stop,
    error,
    clearError: () => setError(undefined),
  };
}
