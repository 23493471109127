import { FC } from "react";
import "@milkdown/prose/view/style/prosemirror.css";
import "@milkdown/prose/tables/style/tables.css";
import "@milkdown/theme-nord/style.css";
import "~/../public/texteditor.css";
import { CustomMarkdown } from "./CustomMarkdown";
import { openReferenceType } from "~/doc-util";
import { ReportType } from "~/api/query_fns/coverage-analysis";

interface CoverageReportEditorProps {
  markdown: string;
  openReference: openReferenceType;
  selectedText?: string;
  reportId: string | undefined;
  reportType: ReportType;
}

export const CoverageReportEditor: FC<CoverageReportEditorProps> = ({
  markdown,
  selectedText = "",
  openReference,
  reportId,
  reportType,
}) => {
  console.log("reportType", reportType);
  const cleanedMarkdown = markdown
    .trim()
    .replace(/^```markdown\n?/, "")
    .replace(/^`/, "");
  return (
    <div className="flex">
      <div className="flex-1 overflow-y-auto">
        <CustomMarkdown
          content={cleanedMarkdown}
          openReference={openReference}
          className="copilotKitMarkdown p-4"
          selectedText={selectedText}
          reportId={reportId}
        />
      </div>
    </div>
  );
};
