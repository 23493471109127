import { Outlet } from "react-router-dom";
import Nav from "./Layout/Nav";
import BillingBanner from "./Layout/BillingBanner";
import {
  AuthContainer,
  AuthBox,
  AuthBoxCenterText,
  AuthBoxLink,
} from "~/components/AuthBox";
import "twin.macro";

/*
url('/images/Qumis-glow-04.png')
url('/images/Qumis-glow-02.png')
*/
``;

//Note the only difference between DefaultLayout and GenLayout is the <main className="..mx-32 .." vs <main className="..mx-auto .."
export const DefaultLayout = () => {
  return (
    <div
      className="flex min-h-screen flex-col"
      style={{ background: "#fbfbfb" }}
    >
      <header className="top-0 z-50 bg-white">
        <div className="custom-shadcn-nav w-full px-4">
          <Nav />
        </div>
        {/* <BillingBanner /> */}
      </header>

      <main className="flex flex-grow items-center justify-center px-4">
        <div className="w-full max-w-7xl">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export const GenLayout = () => {
  return (
    <div
      className="flex min-h-screen flex-col"
      style={{ background: "#fbfbfb" }}
    >
      <header className="top-0 bg-white ">
        <div className="custom-shadcn-nav w-full px-4">
          <Nav />
        </div>
      </header>

      <main className="flex flex-grow items-center justify-center px-4">
        <div className="flex min-h-[75vh] w-full max-w-[95vw] items-center justify-center">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export const SinglePageLayout = () => {
  return (
    <div className="min-h-screen">
      <header className="top-0 z-50 border-gray-300 bg-black opacity-100 shadow-lg">
        <div
          className="w-full px-4"
          style={{
            background: "linear-gradient(90deg, #2F2B2C 0%, #000000 100%)",
          }}
        >
          <Nav />
        </div>
        <BillingBanner />
      </header>
      <main className="max-w-8xl ml-[5%] w-full flex-grow px-4">
        <Outlet />
      </main>
    </div>
  );
};

export const BubblesLayout = () => (
  <article className="h-[100vh] bg-gray-800">
    <AuthContainer>
      <AuthBox>
        <AuthBoxCenterText className="flex items-center justify-center space-x-2">
          <img
            src="/images/Qumis_FullColor_Logomark_RGB.png"
            alt="Qumis"
            className="inline-block w-[50px]"
          />
          <h2 className="text-4xl font-bold text-white">Qumis</h2>
        </AuthBoxCenterText>
        <AuthBoxCenterText tw="text-xs mt-5">
          Your AI Copilot for Complex Risks
        </AuthBoxCenterText>
        <Outlet />
      </AuthBox>
      <AuthBoxCenterText tw="opacity-50 text-xs">
        By continuing, you agree to our{" "}
        <AuthBoxLink to="https://www.qumis.ai/legal/terms-of-service">
          Terms of Service
        </AuthBoxLink>{" "}
        and{" "}
        <AuthBoxLink to="https://www.qumis.ai/legal/privacy-policy">
          Privacy Policy
        </AuthBoxLink>
      </AuthBoxCenterText>
    </AuthContainer>
  </article>
);
