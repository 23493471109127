import React from "react";
import {
  QuoteIcon as RadixQuoteIcon,
  FileTextIcon,
  PlusIcon,
} from "@radix-ui/react-icons";

interface IconProps {
  className?: string;
  size?: number;
}

export const PolicyIcon: React.FC<IconProps> = ({ className, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}
    fill="none"
    stroke="currentColor"
  >
    <path d="M14 3H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9L14 3Z" />
    <path d="M14 3v6h6" />
    <path d="M8 13h8" />
    <path d="M8 17h8" />
    <path d="M8 9h2" />
  </svg>
);

export const QuoteIcon: React.FC<IconProps> = ({ className, size = 24 }) => (
  <RadixQuoteIcon className={className} width={size} height={size} />
);

export const BinderIcon: React.FC<IconProps> = ({ className, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}
    fill="none"
    stroke="currentColor"
  >
    <path d="M4 4v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2z" />
    <circle cx="8" cy="8" r="1" />
    <circle cx="8" cy="12" r="1" />
    <circle cx="8" cy="16" r="1" />
    <path d="M12 8h6" />
    <path d="M12 12h6" />
    <path d="M12 16h6" />
  </svg>
);

export const EndorsementIcon: React.FC<IconProps> = ({
  className,
  size = 24,
}) => (
  <div className="relative inline-flex">
    <FileTextIcon className={className} width={size} height={size} />
    <PlusIcon
      className="absolute -right-1 -top-1"
      width={size / 2}
      height={size / 2}
    />
  </div>
);

export const AttachmentIcon: React.FC<IconProps> = ({
  className,
  size = 24,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}
    fill="none"
    stroke="currentColor"
  >
    <path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48" />
  </svg>
);

export const FactIcon: React.FC<IconProps> = ({ className, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}
    fill="none"
    stroke="currentColor"
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M12 16v-4" />
    <path d="M12 8h.01" />
  </svg>
);

export const ReferenceIcon: React.FC<IconProps> = ({
  className,
  size = 24,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}
    fill="none"
    stroke="currentColor"
  >
    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
  </svg>
);

export const NotesIcon: React.FC<IconProps> = ({ className, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}
    fill="none"
    stroke="currentColor"
  >
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
    <path d="M14 2v6h6" />
    <path d="M16 13H8" />
    <path d="M16 17H8" />
    <path d="M10 9H8" />
  </svg>
);

export const SpecIcon: React.FC<IconProps> = ({ className, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}
    fill="none"
    stroke="currentColor"
  >
    <path d="M3 3v18h18" />
    <path d="m7 12 4-4 4 4 6-6" />
  </svg>
);

export const FileIcon: React.FC<IconProps> = ({ className }) => (
  <FileIcon className={className} />
);
