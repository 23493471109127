import { apiFn } from "~/api/utils";
import {
  PolicyIcon,
  QuoteIcon,
  BinderIcon,
  EndorsementIcon,
  AttachmentIcon,
  FactIcon,
  ReferenceIcon,
  NotesIcon,
  SpecIcon,
} from "~/components/icons/DocumentIcons";
import { CitationServiceDocument } from "./coverage-analysis";

export enum DocumentTypeEnum {
  Policy = "policy",
  Quote = "quote",
  Binder = "binder",
  SingleEndorsement = "single_endorsement",
  Attachment = "attachment",
  Fact = "fact",
  Reference = "reference",
  AdditionalNotes = "additional_notes",
  Spec = "spec",
}

export interface CreateRequestParams {
  filename: string;
  file_hash: string;
  document_type?: DocumentTypeEnum;
  claim_number?: string;
  claim_color?: string;
}

export interface UpdateDocumentTypesRequestParams {
  document_id: string;
  document_type: DocumentTypeEnum;
}

interface GetRequestParams {
  id: string;
  includes?: string;
}

interface IndexRequestParams {
  document_type?: DocumentTypeEnum;
  page?: number;
  uploader?: string;
}

export interface DocumentType {
  id: string;
  filename: string;
  documentType: DocumentTypeEnum;
  organizationName: string;
  uploaderEmail: string;
  createdAt: string;
  claimNumber: string;
  claimColor: string;
  markdown?: string;
  chunks?: {
    text: string;
    page: number;
  }[];
  text?: string;
  textUrl?: string;
  filePath?: string;
  fileHash?: string;
  oId?: string;
  upId?: string;
}

export interface SingleDocResponseData {
  document: DocumentType;
  document_type?: string;
  presignedUrl: string;
  existingDoc: boolean;
}

export interface MultipleDocsResponseData {
  documents: DocumentType[];
  total: number;
}

export const getDocument = apiFn<GetRequestParams, SingleDocResponseData>(
  "GET",
  (params) => `/documents/${params.id}`
);

export const getDocuments = apiFn<IndexRequestParams, MultipleDocsResponseData>(
  "GET",
  "/documents"
);

export const createDocument = apiFn<CreateRequestParams, SingleDocResponseData>(
  "POST",
  "/documents"
);

export const updateDocumentType = apiFn<
  UpdateDocumentTypesRequestParams[],
  { message: string }
>("PATCH", "/documents/update_document_types");

export const destroyDocument = apiFn<{ id: string }, void>(
  "DELETE",
  (params) => `/documents/${params.id}`
);

export const DocumentTypeIcons: Record<
  DocumentTypeEnum,
  React.FC<{ className?: string; size?: number }>
> = {
  [DocumentTypeEnum.Policy]: PolicyIcon,
  [DocumentTypeEnum.Quote]: QuoteIcon,
  [DocumentTypeEnum.Binder]: BinderIcon,
  [DocumentTypeEnum.SingleEndorsement]: EndorsementIcon,
  [DocumentTypeEnum.Attachment]: AttachmentIcon,
  [DocumentTypeEnum.Fact]: FactIcon,
  [DocumentTypeEnum.Reference]: ReferenceIcon,
  [DocumentTypeEnum.AdditionalNotes]: NotesIcon,
  [DocumentTypeEnum.Spec]: SpecIcon,
};

export const convertToServiceDocument = (
  doc: SingleDocResponseData
): CitationServiceDocument => {
  return {
    id: doc.document.id,
    document_type: doc.document.documentType,
    text_url: doc.document.textUrl || "",
    filename: doc.document.filename,
    file_hash: doc.document.fileHash || "", // Using filePath as file_hash since it's the closest match available
  };
};
