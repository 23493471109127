import { useState } from "react";
import {
  InstantSearch,
  connectPagination,
  connectHits,
  Configure,
} from "react-instantsearch-dom";
import { connectSearchBox } from "react-instantsearch-dom";
import { SearchBoxProvided } from "react-instantsearch-core";
import { createTypesenseSearchClient } from "../utils/CreateTypesenseSearchClient";
import { CardHeader, CardTitle } from "~/components/ui/card";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { format } from "date-fns";
import { Badge } from "~/components/ui/badge";
import { Input } from "~/components/ui/input";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Button } from "~/components/ui/button";
import { Paragraph } from "~/components/ui/paragraph";
import { useNavigate } from "react-router-dom";
import { ReportPreview } from "./report/ReportPreview";

interface HitsProps {
  hits: Array<any>;
  showPreview: boolean;
}

interface HitProps {
  hit: {
    id: string;
    filenames: string[];
    coverage: string;
    reportType: string;
    createdAt: number;
    reportName?: string;
    claimNumber?: string;
    analysis?: string;
  };
  showPreview: boolean;
}

const CustomHits: React.FC<HitsProps> = ({ hits, showPreview }) => {
  const navigate = useNavigate();

  const handleHitClick = (hit: any) => {
    navigate(`/report/analysis/${hit.id}`, { state: { hitData: hit } });
  };

  return (
    <div className="hits-wrapper h-[calc(68vh-200px)] overflow-y-auto">
      {hits.length > 0 ? (
        <div className="space-y-4 p-4">
          {hits.map((hit) => (
            <div key={hit.id} onClick={() => handleHitClick(hit)}>
              <Hit hit={hit} showPreview={showPreview} />
            </div>
          ))}
        </div>
      ) : (
        <Paragraph variant="small" className="my-5 p-4 text-muted-foreground">
          No existing reports available.
        </Paragraph>
      )}
    </div>
  );
};

const Hit: React.FC<HitProps> = ({ hit, showPreview }) => {
  const formattedDate = format(hit.createdAt * 1000, "MMM d, yyyy");

  return (
    <div className="custom-shadcn-result-card">
      <CardHeader>
        <CardTitle className="group-hover:underline">
          {hit.reportName || hit.filenames[0] || "Report Name Empty"}
        </CardTitle>

        {formattedDate}

        {hit.reportType !== "policy" && (
          <div className="flex flex-wrap overflow-x-auto">
            {[
              hit.claimNumber && (
                <Badge
                  key="claimNumber"
                  className="m-1"
                  variant="primary"
                  style={{ backgroundColor: "#2660ff" }}
                >
                  Claim Number: {hit.claimNumber}
                </Badge>
              ),
              ...hit.filenames.map((filename, index) => (
                <Badge
                  className="m-1"
                  key={`file-${index}`}
                  variant="primary"
                  style={{ backgroundColor: "darkgrey" }}
                >
                  File: {filename}
                </Badge>
              )),
              // <Badge
              //   key="coverage"
              //   className="m-1"
              //   variant="primary"
              //   style={{ backgroundColor: "lightblue" }}
              // >
              //   Coverage: {showPreview && hit.analysis && hit.analysis.slice(0, 12)}
              // </Badge>,
              <Badge
                key="reportType"
                className="m-1"
                variant="primary"
                style={{ backgroundColor: "lightgreen" }}
              >
                Report Type: {hit.reportType}
              </Badge>,
            ].filter(Boolean)}
          </div>
        )}
      </CardHeader>
      {showPreview && hit.analysis && <ReportPreview analysis={hit.analysis} />}
    </div>
  );
};

const CustomSearchBox = ({
  currentRefinement,
  refine,
  setShowPreview,
}: any) => (
  <form noValidate action="" role="search" className="mb-4">
    <div className="flex w-full items-center">
      <div className="flex-grow">
        <div className="relative">
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-6 w-6 -translate-y-1/2 transform text-gray-500" />
          <Input
            type="search"
            className="pl-11 pr-8"
            value={currentRefinement}
            onChange={(event) => {
              refine(event.currentTarget.value);
              setShowPreview(event.currentTarget.value.length > 0);
            }}
            placeholder="Search job report..."
          />
        </div>
      </div>
    </div>
  </form>
);

const ConnectedSearchBox = connectSearchBox(
  ({
    currentRefinement,
    refine,
    setShowPreview,
  }: SearchBoxProvided & { setShowPreview: (show: boolean) => void }) => (
    <CustomSearchBox
      currentRefinement={currentRefinement}
      refine={refine}
      setShowPreview={setShowPreview}
    />
  )
);
const CustomPagination = ({ currentRefinement, nbPages, refine }: any) => {
  const page = currentRefinement;
  const totalPages = nbPages;

  return (
    <div className="flex items-center space-x-6 lg:space-x-8">
      <div className="ml-auto flex items-center justify-center text-sm text-muted-foreground">
        Page {page} of {totalPages}
      </div>
      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          className="hidden h-8 w-8 p-0 lg:flex"
          onClick={() => refine(1)}
          disabled={page === 1}
        >
          <span className="sr-only">Go to first page</span>
          <DoubleArrowLeftIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="h-8 w-8 p-0"
          onClick={() => refine(page - 1)}
          disabled={page === 1}
        >
          <span className="sr-only">Go to previous page</span>
          <ChevronLeftIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="h-8 w-8 p-0"
          onClick={() => refine(page + 1)}
          disabled={page === totalPages}
        >
          <span className="sr-only">Go to next page</span>
          <ChevronRightIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="hidden h-8 w-8 p-0 lg:flex"
          onClick={() => refine(totalPages)}
          disabled={page === totalPages}
        >
          <span className="sr-only">Go to last page</span>
          <DoubleArrowRightIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

interface JobReportSearchProps {
  report_types: string;
}

const JobReportSearch: React.FC<JobReportSearchProps> = ({ report_types }) => {
  const searchClient = createTypesenseSearchClient("job_reports");
  const ConnectedHits = connectHits(CustomHits);
  const ConnectedPagination = connectPagination(CustomPagination);
  const [showPreview, setShowPreview] = useState(false);

  return (
    <InstantSearch searchClient={searchClient} indexName="job_reports">
      <Configure
        query_by="filenames,analysis"
        filter_by={`report_type:${report_types || ""}`}
        facet_by="report_type,doc_type,user_id,organization_id"
      />
      <ConnectedSearchBox setShowPreview={setShowPreview} />
      <ConnectedHits showPreview={showPreview} />
      <ConnectedPagination />
    </InstantSearch>
  );
};

export default JobReportSearch;
