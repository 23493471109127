import { CopilotApiConfig } from "@copilotkit/react-core";
import { ToolDefinition } from "./copilotkit-utils";
import {
  ChatCompletionEvent,
  decodeChatCompletion,
} from "./decode-chat-completion";
import { parseChatCompletion } from "./parse-chat-completion";
import { Message } from "~/components/CustomMessages";
export const EXCLUDE_FROM_FORWARD_PROPS_KEYS =
  "exclude_from_forward_props_keys";

export interface FetchChatCompletionParams {
  copilotConfig: CopilotApiConfig;
  model?: string;
  messages: Message[];
  tools?: ToolDefinition[];
  temperature?: number;
  maxTokens?: number;
  headers?: Record<string, string> | Headers;
  body?: object;
  signal?: AbortSignal;
  toolChoice?: string | { type: "function"; function: { name: string } };
}

export async function fetchChatCompletion({
  copilotConfig,
  model,
  messages,
  tools,
  temperature,
  headers,
  body,
  signal,
  toolChoice,
}: FetchChatCompletionParams): Promise<Response> {
  temperature ||= 0.5;
  tools ||= [];

  // clean up any extra properties from messages
  const cleanedMessages = messages.map((message) => {
    const { content, role, name, function_call } = message;
    return { content, role, name, function_call };
  });

  toolChoice ||= "auto";

  try {
    const response = await fetch(copilotConfig.chatApiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...copilotConfig.headers,
        ...(headers ? { ...headers } : {}),
      },
      body: JSON.stringify({
        model,
        messages: cleanedMessages,
        stream: true,
        ...(tools.length ? { tools } : {}),
        ...(temperature ? { temperature } : {}),
        ...(tools.length != 0 ? { tool_choice: toolChoice } : {}),
        ...copilotConfig.body,
        ...copilotConfig.backendOnlyProps,
        ...excludeBackendOnlyProps(copilotConfig),
        ...(body ? { ...body } : {}),
        ...(copilotConfig.cloud ? { cloud: copilotConfig.cloud } : {}),
      }),
      signal,
      credentials: copilotConfig.credentials,
    });

    if (!response.ok) {
      let errorMessage: string;
      try {
        const errorData = await response.json();
        errorMessage =
          errorData.error?.message ||
          errorData.message ||
          "Unknown error occurred";
      } catch {
        errorMessage = `HTTP error! status: ${response.status} ${response.statusText}`;
      }

      const error = new Error(errorMessage) as Error & {
        status?: number;
        statusText?: string;
        response?: Response;
      };
      error.status = response.status;
      error.statusText = response.statusText;
      error.response = response;
      throw error;
    }

    return response;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error("Failed to fetch chat completion");
  }
}

function excludeBackendOnlyProps(copilotConfig: any) {
  const backendOnlyProps = copilotConfig.backendOnlyProps ?? {};
  if (Object.keys(backendOnlyProps).length > 0) {
    return {
      [EXCLUDE_FROM_FORWARD_PROPS_KEYS]: Object.keys(backendOnlyProps),
    };
  } else {
    return {};
  }
}

export interface DecodedChatCompletionResponse extends Response {
  events: ReadableStream<ChatCompletionEvent> | null;
}

export async function fetchAndDecodeChatCompletion(
  params: FetchChatCompletionParams
): Promise<DecodedChatCompletionResponse> {
  const response = await fetchChatCompletion(params);
  if (!response.ok || !response.body) {
    (response as any).events = null;
  } else {
    const events = await decodeChatCompletion(
      parseChatCompletion(response.body)
    );
    (response as any).events = events;
  }
  return response as any;
}

export interface DecodedChatCompletionResponseAsText extends Response {
  events: ReadableStream<string> | null;
}

export async function fetchAndDecodeChatCompletionAsText(
  params: FetchChatCompletionParams
): Promise<DecodedChatCompletionResponseAsText> {
  const response = await fetchChatCompletion(params);
  if (!response.ok || !response.body) {
    (response as any).events = null;
  } else {
    const events = await decodeChatCompletionAsText(
      decodeChatCompletion(parseChatCompletion(response.body))
    );
    (response as any).events = events;
  }

  return response as any;
}

function decodeChatCompletionAsText(
  stream: ReadableStream<ChatCompletionEvent>
): ReadableStream<string> {
  const reader = stream.getReader();

  return new ReadableStream<string>({
    async pull(controller) {
      const read = true;
      while (read) {
        try {
          const { done, value } = await reader.read();

          if (done) {
            controller.close();
            return;
          }

          if (value.type === "content") {
            controller.enqueue(value.content);
            continue;
          }
        } catch (error) {
          controller.error(error);
          return;
        }
      }
    },
    cancel() {
      reader.cancel();
    },
  });
}
