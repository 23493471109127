"use client";

import { useState, useMemo } from "react";
import {
  ArrowUpDown,
  Search,
  CheckCircle2,
  XCircle,
  FileText,
  Lightbulb,
  AlertCircle,
} from "lucide-react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";

import { Switch } from "~/components/ui/switch";
import { Button } from "~/components/ui/button";
import { Card, CardHeader, CardContent } from "./ui/card";
import { Input } from "./ui/input";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs";
import CustomDocViewer from "./DocViewer";

interface PolicyCheck {
  fieldName: string;
  policy: {
    value: string;
    page: number;
  };
  status: "match" | "mismatch" | "partial";
  reasoning?: string;
}

interface Sheet {
  name: string;
  data: PolicyCheck[];
}

export default function CoverageChecklistTable() {
  const [filter, setFilter] = useState("");
  const [showMismatchOnly, setShowMismatchOnly] = useState(false);
  const [activeSheet, setActiveSheet] = useState("all");
  const [viewMode, setViewMode] = useState<"table" | "excel">("table");

  const initialData: Sheet[] = [
    {
      name: "General",
      data: [
        {
          fieldName: "General Aggregate Limit",
          policy: { value: "$1,000,000", page: 9 },
          status: "mismatch",
          reasoning: "Expected $2,000,000 but found $1,000,000",
        },
        {
          fieldName: "Carrier Name",
          policy: { value: "Westchester Surplus Lines", page: 5 },
          status: "match",
          reasoning: "Matches exactly with requirements",
        },
        {
          fieldName: "Products/Completed Operations",
          policy: { value: "$1,500,000", page: 7 },
          status: "partial",
          reasoning: "Amount meets minimum but endorsement is missing",
        },
      ],
    },
    {
      name: "Liability",
      data: [
        {
          fieldName: "Policy Period",
          policy: { value: "01/01/2023 - 01/01/2024", page: 1 },
          status: "match",
        },
        {
          fieldName: "Deductible",
          policy: { value: "$5,000", page: 3 },
          status: "mismatch",
          reasoning: "Reason for mismatch",
        },
        {
          fieldName: "Additional Insured",
          policy: { value: "Included with limitations", page: 4 },
          status: "partial",
          reasoning: "Coverage included but with some exclusions",
        },
      ],
    },
  ];

  const allData = useMemo(
    () =>
      initialData.flatMap((sheet) =>
        sheet.data.map((item) => ({ ...item, sheet: sheet.name }))
      ),
    [initialData]
  );

  const activeData =
    activeSheet === "all"
      ? allData
      : initialData.find((sheet) => sheet.name.toLowerCase() === activeSheet)
          ?.data || [];

  const filteredData = activeData.filter(
    (item) =>
      (item.fieldName.toLowerCase().includes(filter.toLowerCase()) ||
        item.policy.value.toLowerCase().includes(filter.toLowerCase())) &&
      (!showMismatchOnly ||
        item.status === "mismatch" ||
        item.status === "partial")
  );

  const mismatchCount = activeData.filter(
    (item) => item.status === "mismatch" || item.status === "partial"
  ).length;

  const handlePageClick = (page: number) => {
    console.log(`Navigating to page ${page}`);
    // Implement page navigation logic here
  };

  const handleInsightsClick = (fieldName: string) => {
    console.log(`Showing insights for ${fieldName}`);
    // Implement insights display logic here
  };

  return (
    <Card className="flex h-[60vh] w-full min-w-[800px] flex-col rounded-xl shadow-lg">
      <CardHeader className="flex-shrink-0 pb-0">
        <div className="flex items-center justify-between">
          <div>
            <p className="mt-1.5 text-sm text-muted-foreground">
              Mismatched Fields:{" "}
              <span className="font-medium text-destructive">
                {mismatchCount}
              </span>{" "}
              / {activeData.length}
            </p>
          </div>

          <div className="flex items-center gap-4">
            <Tabs
              value={viewMode}
              onValueChange={(value: string) =>
                setViewMode(value as "table" | "excel")
              }
              className="w-fit"
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="table">Table View</TabsTrigger>
                <TabsTrigger value="excel">Excel View</TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex flex-grow flex-col overflow-hidden">
        {viewMode !== "excel" && (
          <div className="my-6 flex flex-shrink-0 flex-col items-center justify-between space-y-3 sm:flex-row sm:space-y-0">
            <div className="flex w-full items-center gap-4 sm:w-auto">
              <div className="relative flex-grow sm:w-64">
                <Search className="absolute left-2.5 top-1/2 h-4 w-4 -translate-y-1/2 transform text-muted-foreground" />
                <Input
                  placeholder="Filter fields..."
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="w-full rounded-full border-muted-foreground/20 pl-9 transition-all duration-200 focus:border-muted-foreground/30 focus:ring-1 focus:ring-muted-foreground/30"
                />
              </div>
              <div className="flex items-center gap-2 whitespace-nowrap">
                <Switch
                  id="show-issues"
                  checked={showMismatchOnly}
                  onCheckedChange={setShowMismatchOnly}
                  aria-label="Show only issues"
                  className="data-[state=checked]:bg-black"
                />
                <label htmlFor="show-issues" className="text-sm font-medium">
                  Issues Only
                </label>
              </div>
            </div>
          </div>
        )}

        {viewMode === "excel" ? (
          // Excel View
          <div className="flex-grow overflow-auto">
            <CustomDocViewer
              fileUrl="https://qumis-test-bucket.s3.us-east-1.amazonaws.com/e064523f-7611-4cfa-bd0e-badcf8ee2ce6+(2).xlsx"
              fileType="xlsx"
            />
          </div>
        ) : (
          // Table View
          <>
            <div className="flex flex-grow flex-col overflow-hidden rounded-xl border border-muted-foreground/10 shadow-sm">
              <div className="flex-grow overflow-auto">
                <Table>
                  <TableHeader>
                    <TableRow className="border-b border-muted-foreground/10 hover:bg-muted/5">
                      {activeSheet === "all" && (
                        <TableHead className="w-1/6 px-6 py-4">Sheet</TableHead>
                      )}
                      <TableHead className="w-1/6 px-6 py-4">
                        <Button
                          variant="ghost"
                          className="-ml-4 h-8 p-0 font-semibold hover:bg-transparent"
                        >
                          Field Name
                          <ArrowUpDown className="ml-2 h-4 w-4 opacity-70" />
                        </Button>
                      </TableHead>
                      <TableHead className="w-2/6 px-6 py-4 text-center">
                        Reasoning
                      </TableHead>
                      <TableHead className="w-[8%] px-6 py-4 text-center">
                        Status
                      </TableHead>
                      <TableHead className="w-[8%] px-6 py-4 text-center">
                        Page Number
                      </TableHead>
                      <TableHead className="w-[8%] px-6 py-4 text-center">
                        Insights
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredData.length > 0 ? (
                      filteredData.map((row, index) => (
                        <TableRow
                          key={index}
                          className={`border-b border-muted-foreground/5 transition-colors duration-200 ${
                            row.status === "match"
                              ? "hover:bg-green-50/30"
                              : row.status === "partial"
                              ? "hover:bg-yellow-50/30"
                              : "hover:bg-red-50/30"
                          }`}
                        >
                          {activeSheet === "all" && (
                            <TableCell className="px-6 py-4">
                              {(row as any).sheet}
                            </TableCell>
                          )}
                          <TableCell className="px-6 py-4 font-medium">
                            {row.fieldName}
                          </TableCell>
                          <TableCell
                            className={`px-6 py-4 text-sm ${
                              row.status === "match"
                                ? "text-green-700"
                                : "text-red-700"
                            }`}
                          >
                            {row.reasoning || "-"}
                          </TableCell>
                          <TableCell className="px-6 py-4 text-center">
                            <Button
                              variant="ghost"
                              size="sm"
                              className={`mx-auto h-8 w-28 justify-start gap-1.5 rounded-full px-3 transition-all duration-200 hover:scale-105 hover:bg-background hover:shadow-sm ${
                                row.status === "match"
                                  ? "text-green-700 hover:bg-green-50/50"
                                  : row.status === "partial"
                                  ? "text-yellow-700 hover:bg-yellow-50/50"
                                  : "text-red-700 hover:bg-red-50/50"
                              }`}
                            >
                              {row.status === "match" ? (
                                <CheckCircle2 className="h-3.5 w-3.5" />
                              ) : row.status === "partial" ? (
                                <AlertCircle className="h-3.5 w-3.5" />
                              ) : (
                                <XCircle className="h-3.5 w-3.5" />
                              )}
                              {row.status === "match"
                                ? "Match"
                                : row.status === "partial"
                                ? "Partial"
                                : "Mismatch"}
                            </Button>
                          </TableCell>
                          <TableCell className="px-6 py-4 text-center">
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handlePageClick(row.policy.page)}
                              className="mx-auto h-8 gap-1.5 rounded-full px-3 transition-all duration-200 hover:scale-105 hover:bg-background hover:shadow-sm"
                            >
                              <FileText className="h-4 w-4 text-muted-foreground" />
                              #{row.policy.page}
                            </Button>
                          </TableCell>
                          <TableCell className="px-6 py-4 text-center">
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleInsightsClick(row.fieldName)}
                              className="mx-auto h-8 gap-1.5 rounded-full px-3 transition-all duration-200 hover:scale-105 hover:bg-background hover:shadow-sm"
                            >
                              <Lightbulb className="h-4 w-4 text-muted-foreground" />
                              Insights
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={activeSheet === "all" ? 6 : 5}
                          className="h-32 text-center text-muted-foreground"
                        >
                          No results found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>

            <Tabs
              value={activeSheet}
              onValueChange={setActiveSheet}
              className="mt-4 flex-shrink-0"
            >
              <TabsList className="inline-flex h-9 items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground">
                <TabsTrigger value="all" className="...">
                  All Fields
                </TabsTrigger>
                {initialData.map((sheet) => (
                  <TabsTrigger
                    key={sheet.name.toLowerCase()}
                    value={sheet.name.toLowerCase()}
                    className="..."
                  >
                    {sheet.name}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          </>
        )}
      </CardContent>
    </Card>
  );
}
