import { useState } from "react";
import { Heading } from "~/components/ui/heading";
import { Paragraph } from "~/components/ui/paragraph";
import { Card } from "~/components/ui/card";
import UploadArea from "./Documents/UploadArea";
import {
  DocumentTypeEnum,
  SingleDocResponseData,
} from "~/api/query_fns/documents";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import StepComponentGenerateReportButton from "~/components/report/StepComponentGenerateReportButton";
import { LoadingComponent } from "~/utils/util-report";
import { useGenerateReport } from "~/hooks/useGenerateReport";
import AdditionalInformation from "~/components/AdditionalInformation";

const UCReportSelection = () => {
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [reportType, setReportType] = useState("");

  // Add new state variables
  const [uploadedDocs, setUploadedDocs] = useState<SingleDocResponseData[]>([]);

  // Function to handle the upload completion
  const handleUploadComplete = (docResponse: SingleDocResponseData) => {
    console.log("Current document: ", docResponse);
    setUploadedDocs((prevDocs) => {
      console.log("Existing documents: ", prevDocs);
      return [...prevDocs, docResponse];
    });
  };

  // Function to handle the deletion of an uploaded document
  const handleDeleteUploadedDoc = (docId: string) => {
    setUploadedDocs((prevDocs) =>
      prevDocs.filter((doc) => doc.document.id !== docId)
    );
  };

  // Check if both types of documents are uploaded
  const areBothTypesUploaded =
    uploadedDocs.some((doc) => doc.document.documentType === "fact") &&
    uploadedDocs.some((doc) => doc.document.documentType === "policy");

  console.log("Are both types of documents uploaded: ", areBothTypesUploaded);

  // Check if at least two policy documents are uploaded
  const areTwoPoliciesUploaded =
    uploadedDocs.filter((doc) => doc.document.documentType === "policy")
      .length >= 2;

  const { handleGenerateReport, isButtonDisabled, isLoading } =
    useGenerateReport(uploadedDocs, reportType, additionalNotes);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div id="parentCoverageContainer">
      <div id="selectReportType" className=" grid grid-cols-2 gap-12">
        <div
          className="flex w-full flex-col justify-start"
          style={{ gridColumn: "1 / span 2" }}
        >
          <div>
            <Heading as="h1" className="my-5">
              Generate Report
            </Heading>
          </div>
          <div className="mt-1 flex items-center ">
            <div className="">
              <Select onValueChange={(value: string) => setReportType(value)}>
                <SelectTrigger className="w-[280px]">
                  <SelectValue placeholder="SELECT A REPORT TYPE" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="policy_fact">Claim Coverage</SelectItem>
                    <SelectItem value="policies_compare">
                      Policy Comparison
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
      {reportType !== "" ? (
        <>
          <div
            id="uploadArea"
            className=" flex grid w-full grid-cols-2 flex-col justify-start gap-12"
            style={{ gridColumn: "1 / span 2" }}
          >
            <div className="flex flex-col gap-2">
              <Heading as="h2">Policy Doc(s)</Heading>
              <Card className="p-3">
                <UploadArea
                  showSearch={false}
                  db_document_type={DocumentTypeEnum.Policy}
                  onUploadComplete={handleUploadComplete}
                  onDeleteDocument={handleDeleteUploadedDoc}
                />
              </Card>
            </div>
            {reportType === "policy_fact" && (
              <div className="flex flex-col gap-2">
                <Heading as="h2">Fact Pattern(s)</Heading>
                <Card className="p-3">
                  <UploadArea
                    showSearch={false}
                    db_document_type={DocumentTypeEnum.Fact}
                    onUploadComplete={handleUploadComplete}
                    onDeleteDocument={handleDeleteUploadedDoc}
                  />
                </Card>
              </div>
            )}
          </div>
          <div id="additionalInfoComponent">
            <AdditionalInformation
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              handleUploadComplete={handleUploadComplete}
              handleDeleteUploadedDoc={handleDeleteUploadedDoc}
            />
          </div>
          <div id="bottomCoverageComponent" className="m-5 ">
            <div
              className="flex w-full justify-center"
              style={{ gridColumn: "1 / span 2" }}
            >
              {reportType === "policy_fact" && (
                <>
                  {!areBothTypesUploaded && (
                    <Paragraph className="text-black-100">
                      *Upload both a policy and a fact pattern document to
                      proceed.
                    </Paragraph>
                  )}
                </>
              )}
              {reportType === "policies_compare" && (
                <>
                  {!areTwoPoliciesUploaded && (
                    <Paragraph className="m-2">
                      *Please upload two or more policies to compare.
                    </Paragraph>
                  )}
                </>
              )}
            </div>
            <div
              className="flex w-full justify-center"
              style={{ gridColumn: "1 / span 2" }}
            >
              <StepComponentGenerateReportButton
                handleGenerateReport={handleGenerateReport}
                isButtonDisabled={isButtonDisabled}
              />
            </div>
          </div>
        </>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", alignItems: "" }}
        >
          <Heading as="h2" className="my-5">
            *Please Select Report Type
          </Heading>
        </div>
      )}
    </div>
  );
};

export default UCReportSelection;
