import { useChatContext } from "./ChatContext";
import { ResponseButtonProps } from "./props";

export const ResponseButton = ({
  onClick,
  inProgress,
}: ResponseButtonProps) => {
  const context = useChatContext();
  return (
    <button onClick={onClick} className="copilotKitResponseButton">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-4 w-4"
      >
        {inProgress ? (
          // Stop icon - square shape
          <rect x="6" y="6" width="12" height="12" />
        ) : (
          // Regenerate icon - circular arrow
          <svg>
            <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
            <path d="M3 3v5h5" />
            <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
            <path d="M16 21h5v-5" />
          </svg>
        )}
      </svg>
      <span className="ml-2">
        {inProgress
          ? context.labels.stopGenerating
          : context.labels.regenerateResponse}
      </span>
    </button>
  );
};
