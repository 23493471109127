import React, { FC, memo, useState, useEffect } from "react";
import ReactMarkdown, { Components, Options } from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkDirective from "remark-directive";
import { visit } from "unist-util-visit";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import { OpenInNewWindowIcon, CheckIcon } from "@radix-ui/react-icons";

/*
import InsuranceTowerAnalysisChart from "./report/artifacts/InsuranceTowerAnalysisChart";
import InsurancePolicyRadarChart from "./report/artifacts/InsurancePolicyComparisonRadarChart";
import InsurancePolicyLimitsTableChart from "./report/artifacts/InsurancePolicyLimitsTableChart"; 
*/
import { openReferenceType, NEW_SPLITTER, parseReference } from "~/doc-util";
import CustomMarkdownTable from "./CustomMarkdownTable";
import CustomMarkdownCheckListTable from "./CustomMarkdownCheckListTable";

export type ChartContextType = {
  reportId?: string;
  documentId?: string;
};

// Update the context creation
export const ChartContext = React.createContext<ChartContextType>({});

// At the top of the file, update this type definition
type CustomComponentType = React.FC<{ children?: React.ReactNode }>;

// Add these exports
export const REFERENCE_REGEX = /\[(.*?)\]/g;

export const ReferenceLink: FC<{
  text: string;
  openReference: (text: string) => void;
  isSelected?: boolean;
}> = ({ text, openReference, isSelected }) => {
  const { filename, page, section, status } = parseReference(text);

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span
            role="button"
            tabIndex={0}
            onClick={(event) => {
              event.preventDefault();
              openReference(text);
            }}
            className={`inline-flex cursor-pointer items-center ${
              isSelected ? "bg-yellow-200" : ""
            }`}
          >
            <span className="relative mr-2 inline-flex">
              <OpenInNewWindowIcon className="h-4 w-4 text-blue-500" />
              {status === true && (
                <>
                  <CheckIcon className="absolute -bottom-1 -right-2 ml-2 h-3 w-3 text-green-500" />
                  <CheckIcon className="absolute -bottom-1 -right-3 ml-2 h-3 w-3 text-green-500" />
                </>
              )}
            </span>
          </span>
        </TooltipTrigger>
        <TooltipContent className="z-50">
          <span className="flex max-w-[350px] flex-col gap-1 p-2">
            <span className="flex items-center gap-2">
              <span className="font-medium">File:</span>
              <span className="break-all text-sm text-gray-600">
                {filename}
              </span>
            </span>
            <span className="flex items-center gap-2">
              <span className="font-medium">Page:</span>
              <span className="break-all text-sm text-gray-600">{page}</span>
            </span>
            {section && (
              <span className="flex items-center gap-2">
                <span className="font-medium">Section:</span>
                <span className="break-all text-sm text-gray-600">
                  {section}
                </span>
              </span>
            )}
          </span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const processReferenceText = (
  text: string,
  openReference: (text: string) => void,
  selectedText?: string
) => {
  console.log("Processing reference text:", text);
  const parts = text.split(REFERENCE_REGEX);
  return parts.map((part, index) => {
    if (index % 2 === 1) {
      // This is a reference part
      const id = part.split(NEW_SPLITTER)[0]?.trim(); // Get the first value before pipe as key
      console.log("Part:", part);
      console.log("ID:", id);
      return (
        <ReferenceLink
          key={id || index} // Use ID if available, fallback to index
          text={part}
          openReference={openReference}
          isSelected={!!(selectedText && part.includes(selectedText))}
        />
      );
    }
    return part;
  });
};

const MemoizedReactMarkdown: FC<Options> = memo(
  (props) => <ReactMarkdown {...props} className={"milkdown-theme-nord"} />,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className
);

type MarkdownProps = {
  content: string;
  openReference: openReferenceType;
  selectedText?: string; // Ensure this is optional
};

// In CustomParagraph
const CustomParagraph: FC<{
  children?: React.ReactNode;
  openReference: (text: string) => void;
  selectedText?: string; // Ensure this is optional
}> = ({ children, openReference, selectedText }) => {
  return (
    <div className="mb-4">
      {React.Children.map(children, (child) =>
        typeof child === "string"
          ? child.split("\n").map((line, index, array) => (
              <React.Fragment key={index}>
                {processReferenceText(line, openReference, selectedText)}
                {index < array.length - 1 && line.trim().length > 0 && <br />}
              </React.Fragment>
            ))
          : child
      )}
    </div>
  );
};

// In CustomListItem
const CustomListItem: FC<{
  children?: React.ReactNode;
  openReference: (text: string) => void;
}> = ({ children, openReference, ...props }) => {
  const processChildren = (child: React.ReactNode): React.ReactNode => {
    if (typeof child === "string") {
      return processReferenceText(child, openReference);
    }
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement, {
        children: React.Children.map(child.props.children, processChildren),
      });
    }
    return child;
  };

  return <li {...props}>{React.Children.map(children, processChildren)}</li>;
};

// In CustomPreBlock
const CustomPreBlock: FC<{
  children?: React.ReactNode;
  selectedText?: string;
  openReference: (text: string) => void;
}> = ({ children, openReference }) => {
  const selectedText = window.getSelection()?.toString();

  return (
    <div className="custom-pre">
      {React.Children.map(children, (child) => {
        if (typeof child === "string") {
          return processReferenceText(child, openReference, selectedText);
        }
        return child;
      })}
    </div>
  );
};

// Import custom components directly
const CustomNote: FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="mb-4 border-l-4 border-blue-500 bg-blue-100 p-4 text-blue-700">
    <p className="font-bold">Note</p>
    {children}
  </div>
);

/*
Example usage:
:::policyComparisonLimitsChart
:::


:::insuranceTowerAnalysisChart
:::
*/
const customComponents: Record<string, CustomComponentType> = {
  note: ({ children }) => <CustomNote>{children}</CustomNote>,
  policyComparisonLimitsChart: () => null,
  insuranceTowerAnalysisChart: () => null,
  policyComparisonRadarChart: () => null,
  /*
    policyComparisonLimitsChart: () => <InsurancePolicyLimitsTableChart />,
    insuranceTowerAnalysisChart: () => <InsuranceTowerAnalysisChart />,
    policyComparisonRadarChart: () => <InsurancePolicyRadarChart />
  */
};

const customDirectivePlugin = () => {
  return (tree: any) => {
    visit(tree, (node) => {
      if (
        node.type === "containerDirective" ||
        node.type === "leafDirective" ||
        node.type === "textDirective"
      ) {
        if (!customComponents[node.name]) return;

        const data = node.data || (node.data = {});
        const tagName = "div";

        data.hName = tagName;
        data.hProperties = {
          ...data.hProperties,
          className: `custom-${node.name}`,
        };
      }
    });
  };
};

export const CustomMarkdown: FC<
  MarkdownProps & {
    maxHeight?: string;
    className: string;
    reportId?: string;
    documentId?: string;
  }
> = ({
  content,
  openReference,
  selectedText,
  maxHeight,
  className,
  reportId,
  documentId,
}) => {
  const [tableType, setTableType] = useState<"checklist" | "standard" | null>(
    null
  );

  // Add this line to clean the content before processing
  const cleanedContent = cleanCodeBlocks(content);

  useEffect(() => {
    const determineTableType = () => {
      // Use cleanedContent instead of content here
      const tableHeaderMatch = cleanedContent.match(/\|([^|]+)\|/g);
      if (tableHeaderMatch) {
        const headers = tableHeaderMatch.map((header) =>
          header.toLowerCase().trim()
        );
        const hasStatusColumn = headers.some(
          (header) =>
            header.includes("status") ||
            header.includes("complete") ||
            header.includes("done")
        );
        setTableType(hasStatusColumn ? "checklist" : "standard");
      } else {
        setTableType("standard");
      }
    };

    determineTableType();
  }, [cleanedContent]); // Update dependency

  if (tableType === null) {
    return null;
  }

  const components: Components = {
    p: ({ children, ...props }) => (
      <CustomParagraph
        openReference={openReference}
        selectedText={selectedText}
        {...props}
      >
        {children}
      </CustomParagraph>
    ),
    pre: ({ children, ...props }) => (
      <CustomPreBlock
        {...props}
        openReference={openReference}
        selectedText={selectedText}
      >
        {children}
      </CustomPreBlock>
    ),
    code: ({ children, ...props }) => <code {...props}>{children}</code>,
    li: ({ ...props }) => (
      <CustomListItem
        className="custom-paragraph"
        {...props}
        openReference={openReference}
      />
    ),
    img: ({ ...props }) => (
      <img
        style={{ maxWidth: "100%", margin: "0", display: "inline-block" }}
        {...props}
      />
    ),
    blockquote: ({ ...props }) => (
      <blockquote style={{ fontWeight: "normal" }} {...props} />
    ),
    ol: ({ ...props }) => (
      <ol style={{ listStyleType: "decimal" }} {...props} />
    ),
    ul: ({ ...props }) => <ul style={{ listStyleType: "disc" }} {...props} />,
    table: ({ ...props }) => {
      return tableType === "checklist" ? (
        <CustomMarkdownCheckListTable
          className="custom-paragraph"
          {...props}
          openReference={openReference}
          selectedText={selectedText}
        />
      ) : (
        <CustomMarkdownTable
          className="custom-paragraph"
          {...props}
          openReference={openReference}
          selectedText={selectedText}
        />
      );
    },
    td: ({ ...props }) => (
      <td
        style={{
          border: "1px solid #E5E7EB",
          padding: "0.75rem 1.5rem",
          textAlign: "center",
        }}
        {...props}
      />
    ),
    th: ({ ...props }) => (
      <th style={{ padding: "0.75rem 1.5rem" }} {...props} />
    ),
    tr: ({ ...props }) => (
      <tr style={{ border: "1px solid #E5E7EB" }} {...props} />
    ),
    div: ({ className, children, ...props }) => {
      const match = className?.match(/^custom-(.+)$/);
      if (match && match[1] in customComponents) {
        const CustomComponent =
          customComponents[match[1] as keyof typeof customComponents];
        return <CustomComponent {...props}>{children}</CustomComponent>;
      }
      return (
        <div className={className} {...props}>
          {children}
        </div>
      );
    },
  };

  return (
    <ChartContext.Provider value={{ reportId, documentId }}>
      <TooltipProvider>
        <div
          className={`${className} prose prose-sm relative max-w-none selection:bg-yellow-200 selection:text-black`}
          style={{ maxHeight: maxHeight || undefined }}
        >
          <MemoizedReactMarkdown
            components={components}
            remarkPlugins={[remarkGfm, remarkDirective, customDirectivePlugin]}
            className="space-y-4"
          >
            {cleanedContent}
          </MemoizedReactMarkdown>
        </div>
      </TooltipProvider>
    </ChartContext.Provider>
  );
};

/* const testChart = `:::insuranceChart
:::


:::insuranceRadarChart
:::

` */

const cleanCodeBlocks = (content: string): string => {
  return (
    content
      // Handle fenced code blocks (```...)
      .replace(/^```[\w-]*\n([\s\S]*?)^```$/gm, (_, codeContent) =>
        codeContent.trim()
      )
      // Handle indentation-based code blocks (4+ spaces or tab)
      .replace(/^(?:[ ]{4,}|\t+)(.*?)$/gm, (_, line) => line.trim())
  );
};
