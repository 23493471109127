import React, { useMemo } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

interface DocViewerProps {
  fileUrl: string;
  fileType: string;
}

const CustomDocViewer: React.FC<DocViewerProps> = ({ fileUrl, fileType }) => {
  const docs = useMemo(() => [{ uri: fileUrl, fileType }], [fileUrl, fileType]);

  return useMemo(
    () => (
      <div style={{ minHeight: "500px" }}>
        <DocViewer
          config={{ header: { disableHeader: true } }}
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100vh" }}
        />
      </div>
    ),
    [docs]
  );
};

export default CustomDocViewer;
