import React from "react";
import { Button } from "~/components/ui/button";
import { useNavigate } from "react-router-dom";

interface ReportGenerationButtonProps {
  buttonText: string;
  route: string;
  beta?: boolean;
}

const UCReportGenerationButtonComponent: React.FC<
  ReportGenerationButtonProps
> = ({ buttonText, route, beta }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(route);
  };

  return (
    <div className="relative flex h-full items-center justify-center">
      {beta && (
        <div className="group absolute right-2 top-2">
          <img
            src="/images/beta-100.png"
            alt="Beta"
            className="h-8 w-auto cursor-help"
          />
          <div className="absolute right-0 top-full z-10 mt-2 hidden w-48 rounded bg-gray-800 p-2 text-sm text-white shadow-lg group-hover:block">
            This feature is in beta. We are continuously working to improve the
            results.
          </div>
        </div>
      )}
      <Button
        onClick={handleClick}
        variant="default"
        size="sm"
        className="min-h-[40px] min-w-[420px] bg-black text-white hover:bg-gray-800"
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default UCReportGenerationButtonComponent;
