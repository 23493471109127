import { FC } from "react";
import { StatusType, STATUS_MAPPINGS } from "./util-customchecklist";

interface StatusMetricsGridProps {
  statusCounts: Record<StatusType, number>;
  selectedStatuses: StatusType[];
  onStatusToggle: (status: StatusType) => void;
}

export const StatusMetricsGrid: FC<StatusMetricsGridProps> = ({
  statusCounts,
  selectedStatuses,
  onStatusToggle,
}) => (
  <div className="grid grid-cols-5 gap-4">
    <div className="flex h-full flex-col items-center justify-center rounded-lg border bg-background px-4 py-2 shadow-sm">
      <span className="text-center text-sm font-medium text-muted-foreground">
        Total
      </span>
      <span className="text-center text-2xl font-bold">
        {statusCounts.total || 0}
      </span>
    </div>

    {Object.entries(STATUS_MAPPINGS).map(([status, config]) => (
      <div
        key={status}
        className={`flex h-full flex-col items-center justify-center px-4 py-2 ${
          config.gridClass
        } cursor-pointer rounded-lg border shadow-sm transition-all hover:scale-105 ${
          selectedStatuses.includes(status as StatusType)
            ? `ring-2 ring-${config.color}-500`
            : ""
        }`}
        onClick={() => onStatusToggle(status as StatusType)}
      >
        <span
          className={`text-sm font-medium text-${config.color}-600 text-center`}
        >
          {config.label}
        </span>
        <span
          className={`text-2xl font-bold text-${config.color}-700 text-center`}
        >
          {statusCounts[status as StatusType] || 0}
        </span>
      </div>
    ))}
  </div>
);
