import { HeaderProps } from "./props";
import { useChatContext } from "./ChatContext";

export const Header = ({
  setOpen,
  isFullscreen,
  onToggleFullscreen,
}: HeaderProps) => {
  const context = useChatContext();
  console.log("onToggleFullscreen", onToggleFullscreen);
  console.log("setOpen", setOpen);
  return (
    <div
      className={`copilotKitHeader ${
        isFullscreen ? "fullscreen" : ""
      } flex items-center justify-between`}
    >
      <div className="flex-1"></div>
      <div className="flex-1 text-center">{context.labels.title}</div>
      <div className="flex flex-1 justify-end gap-2">
        {/*         <button 
          onClick={onToggleFullscreen}
          aria-label={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
          className="flex items-center justify-center rounded-md p-2 text-white transition-colors hover:bg-white/10"
        >
          {isFullscreen ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"/>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7"/>
            </svg>
          )}
        </button> */}
      </div>
    </div>
  );
};
