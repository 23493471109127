import { useMutation } from "react-query";
import {
  Citation,
  getCitations,
  postChatMessage,
  postSlackNotification,
} from "~/api/query_fns/coverage-analysis";
import {
  convertToServiceDocument,
  SingleDocResponseData,
} from "~/api/query_fns/documents";
import { CustomChatInput } from "~/components/CustomChatInput";
import {
  CustomMessagesWithReference,
  Message,
} from "~/components/CustomMessages";
import { openReferenceType, ReportSourceType } from "~/doc-util";
import { MessagesProps } from "~/components/chat/copilotkit-utils";
import React, { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { CopilotModalProps, CopilotModal } from "./chat/Modal";
import { TextSelectionMenuOption } from "./TextSelectionMenu";

interface CustomChatProps {
  context: string;
  initialMessage: string;
  reportKey: string;
  documents: SingleDocResponseData[];
  openReference: openReferenceType;
  reportSource: ReportSourceType;
  userId?: string;
  reportId?: string;
  reportSourcePostFix?: string;
  keyId?: string;
  children?: React.ReactNode;
  appendToReportChatMessages?: (messages: Message[]) => void;
  initialChatMessages?: Message[];
  selectedTextMenuOption?: TextSelectionMenuOption;
  clearSelectedTextMenuOption?: () => void;
  oId: string;
  addCitations: (citations: Citation[]) => void;
}

const CustomChat = React.memo(
  ({
    context,
    initialMessage,
    reportId,
    documents,
    userId,
    reportKey,
    openReference,
    reportSource,
    appendToReportChatMessages,
    initialChatMessages = [],
    selectedTextMenuOption,
    clearSelectedTextMenuOption,
    oId,
    addCitations,
  }: CustomChatProps) => {
    const postMessageMutation = useMutation(postChatMessage);
    const [isCopilotOpen, setIsCopilotOpen] = useState(true);
    const [ratings, setRatings] = useState<Record<string, number>>({});

    const chatId = useMemo(() => {
      if (initialChatMessages && initialChatMessages.length > 0) {
        return initialChatMessages[0].chatId || uuidv4();
      }
      return uuidv4();
    }, [initialChatMessages]);

    const MessagesComponent = useMemo(() => {
      return (props: MessagesProps) => (
        <CustomMessagesWithReference
          {...props}
          openReference={openReference}
          ratings={ratings}
          setRatings={setRatings}
        />
      );
    }, [openReference, ratings, setRatings]);

    return (
      <div
        className={`copilotKitSidebarContentWrapper flex h-full flex-col ${
          isCopilotOpen ? "sidebarExpanded" : ""
        }`}
      >
        <CopilotSidebar
          clickOutsideToClose={false}
          labels={{
            title: "Qumis Clerk",
            initial: initialMessage,
          }}
          defaultOpen={true}
          makeSystemMessage={() => context}
          initialChatMessages={initialChatMessages}
          Messages={MessagesComponent}
          onSetOpen={(open) => setIsCopilotOpen(open)}
          className="py-6 pt-8 "
          Input={(props) => (
            <CustomChatInput
              {...props}
              chatContentContext={context}
              selectedTextMenuOption={selectedTextMenuOption}
              clearSelectedTextMenuOption={clearSelectedTextMenuOption}
            />
          )}
          chatId={chatId}
          onSubmitMessage={(message: Message) => {
            console.log("onSubmitMessageonSubmitMessage", chatId);
            postMessageMutation.mutate({
              id: message.id,
              report_source: reportSource,
              message: message.content,
              report_id: reportId,
              document_ids: documents.map((doc) => doc.document.id).join(","),
              user_id: userId,
              report_key: reportKey,
              chat_id: chatId,
              message_type: "user",
            });
          }}
          messageGenerationCompleteBeforeFinalization={async (
            aiMessageResponse
          ) => {
            const { response, citations } = await getCitations({
              content: aiMessageResponse,
              documents: documents.map(convertToServiceDocument),
              o_id: oId,
            });
            addCitations(citations);
            return response;
          }}
          onResponseComplete={(currentUserMessage, currentAIMessage) => {
            console.log("onResponseCompleteonResponseComplete", chatId);
            console.log("currentUserMessage", currentUserMessage);
            console.log("currentAIMessage", currentAIMessage);
            postMessageMutation.mutate(
              {
                id: currentAIMessage.id,
                report_source: reportSource,
                message: currentAIMessage.content,
                report_id: reportId,
                document_ids: documents.map((doc) => doc.document.id).join(","),
                user_id: userId,
                report_key: reportKey,
                chat_id: chatId,
                message_type: "assistant",
              },
              {
                onSuccess: (response) => {
                  console.log("Message posted successfully:", response);
                  if (appendToReportChatMessages) {
                    appendToReportChatMessages([
                      {
                        id: currentUserMessage.id,
                        content: currentUserMessage.content,
                        role: "user",
                        isVisible: true,
                      },
                      {
                        id: currentAIMessage.id,
                        content: currentAIMessage.content,
                        role: "assistant",
                        isVisible: true,
                      },
                    ]);
                  }
                },
              }
            );
          }}
          onErrorMessage={(error) => {
            console.error("An error occurred:", error);
            try {
              postSlackNotification({
                text: `Chat Error Occurred: ${error}\n User ID: ${userId}\n Organization ID: ${oId}\n Report ID: ${reportId}\n Chat ID: ${chatId}\n Document IDs: ${documents
                  .map((doc) => doc.document.id)
                  .join(",")}`,
              });
            } catch (error) {
              console.error("Failed to send Slack notification:", error);
            }
            return "Oops! We're looking into this and will fix it soon.";
          }}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.context === nextProps.context &&
      prevProps.initialMessage === nextProps.initialMessage &&
      prevProps.reportId === nextProps.reportId &&
      prevProps.userId === nextProps.userId &&
      prevProps.reportKey === nextProps.reportKey &&
      areMessagesEqual(
        prevProps.initialChatMessages ?? [],
        nextProps.initialChatMessages ?? []
      ) &&
      prevProps.selectedTextMenuOption === nextProps.selectedTextMenuOption
    );
  }
);

export function CopilotSidebar(props: CopilotModalProps) {
  props = {
    ...props,
    hitEscapeToClose: false,
    className: props.className
      ? props.className + " copilotKitSidebar"
      : "copilotKitSidebar",
  };
  const [expandedClassName, setExpandedClassName] = useState(
    props.defaultOpen ? "sidebarExpanded" : ""
  );

  const onSetOpen = (open: boolean) => {
    props.onSetOpen?.(open);
    setExpandedClassName(open ? "sidebarExpanded" : "");
  };

  return (
    <div className={`copilotKitSidebarContentWrapper ${expandedClassName}`}>
      <CopilotModal {...props} {...{ onSetOpen }}>
        {props.children}
      </CopilotModal>
    </div>
  );
}

export function CopilotCustomLayout(props: CopilotModalProps) {
  props = {
    ...props,
    className: props.className
      ? props.className + " copilotKitSidebar"
      : "copilotKitSidebar",
  };
  const [expandedClassName, setExpandedClassName] = useState(
    props.defaultOpen ? "sidebarExpanded" : ""
  );

  const onSetOpen = (open: boolean) => {
    props.onSetOpen?.(open);
    setExpandedClassName(open ? "sidebarExpanded" : "");
  };

  return (
    <div className={`copilotKitSidebarContentWrapper ${expandedClassName}`}>
      <CopilotModal {...props} {...{ onSetOpen }}>
        {props.children}
      </CopilotModal>
    </div>
  );
}

export { CustomChat };

const areMessagesEqual = (
  prevMessages: Message[],
  nextMessages: Message[]
): boolean => {
  if (prevMessages.length !== nextMessages.length) return false;

  return prevMessages.every((prevMessage, index) => {
    const nextMessage = nextMessages[index];
    return (
      prevMessage.id === nextMessage.id &&
      prevMessage.content === nextMessage.content &&
      prevMessage.role === nextMessage.role &&
      prevMessage.function_call === nextMessage.function_call &&
      prevMessage.partialFunctionCall === nextMessage.partialFunctionCall
    );
  });
};
