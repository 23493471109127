import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { Badge } from "~/components/ui/badge";
import { ScrollArea } from "~/components/ui/scroll-area";
import {
  CategoryCount,
  StatusType,
  STATUS_MAPPINGS,
  isValidCategory,
} from "./util-customchecklist";

interface CategoryFilterMenuProps {
  categories: CategoryCount[];
  selectedCategories: string[];
  onCategoryToggle: (categoryName: string) => void;
  rows: React.ReactNode[][];
  getCategoryStatusCounts: (
    rows: React.ReactNode[][],
    category: string
  ) => Record<StatusType, number>;
  getTotalStatusCounts: (
    rows: React.ReactNode[][]
  ) => Record<StatusType, number>;
}

export const CategoryFilterMenu: React.FC<CategoryFilterMenuProps> = ({
  categories,
  selectedCategories,
  onCategoryToggle,
  rows,
  getCategoryStatusCounts,
  getTotalStatusCounts,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleCategoryClick = (categoryName: string) => {
    onCategoryToggle(categoryName);
    setIsOpen(true);
  };

  const getStatusBadgeClass = (status: keyof typeof STATUS_MAPPINGS) => {
    const mapping = STATUS_MAPPINGS[status];
    return mapping?.badgeClass ?? "bg-muted/60";
  };

  const renderStatusBadges = (statusCounts: Record<StatusType, number>) => {
    const orderedStatuses = Object.keys(STATUS_MAPPINGS) as StatusType[];

    return orderedStatuses
      .filter((status) => status in statusCounts)
      .map((status) => (
        <Badge
          key={status}
          variant="secondary"
          className={status !== "total" ? getStatusBadgeClass(status) : ""}
        >
          {statusCounts[status] || 0}
        </Badge>
      ));
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger className="flex w-[280px] items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm">
        <span className="flex items-center gap-1">
          {selectedCategories.length === categories.length
            ? "Categories"
            : selectedCategories[0] || "Filter Categories"}
        </span>
        <div className="flex gap-2">
          {selectedCategories.length === 1 &&
            selectedCategories[0] &&
            renderStatusBadges(
              getCategoryStatusCounts(rows, selectedCategories[0])
            )}
          {selectedCategories.length === categories.length &&
            renderStatusBadges(getTotalStatusCounts(rows))}
          {selectedCategories.length === 0 && (
            <Badge variant="secondary" className="bg-muted/60">
              Select category
            </Badge>
          )}
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-[280px]">
        <DropdownMenuItem
          className={`flex justify-between ${
            selectedCategories.length === categories.length ? "bg-muted" : ""
          }`}
          onClick={() => handleCategoryClick("all")}
        >
          <span className="font-medium">Categories</span>
          <div className="flex gap-1">
            {renderStatusBadges(getTotalStatusCounts(rows))}
          </div>
        </DropdownMenuItem>

        <div className="my-1 border-t border-muted-foreground/10" />

        <ScrollArea className="h-[300px]">
          {categories
            .filter(({ name }) => isValidCategory(name))
            .map(({ name }) => {
              const statusCounts = getCategoryStatusCounts(rows, name);
              return (
                <DropdownMenuItem
                  key={name}
                  className={`flex justify-between ${
                    selectedCategories.includes(name) &&
                    selectedCategories.length === 1
                      ? "bg-muted"
                      : ""
                  }`}
                  onClick={() => handleCategoryClick(name)}
                >
                  <span>{name}</span>
                  <div className="flex gap-1">
                    {renderStatusBadges(statusCounts)}
                  </div>
                </DropdownMenuItem>
              );
            })}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
