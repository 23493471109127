import React from "react";
import { CustomMarkdown } from "../CustomMarkdown";

interface ReportPreviewProps {
  analysis: string;
}

export const ReportPreview: React.FC<ReportPreviewProps> = ({ analysis }) => {
  return (
    <div className="rounded-b-lg bg-gray-50 p-4">
      <h4 className="mb-2 text-sm font-semibold text-gray-700">Preview:</h4>
      <div className="rounded-md bg-white p-3 shadow-sm">
        <CustomMarkdown
          content={analysis.slice(0, 250) + "..."}
          openReference={() => ({ isOpen: false })}
          className="prose prose-sm max-w-none text-sm text-gray-600"
        />
      </div>
      <div className="mt-2 text-right">
        <span className="text-xs italic text-gray-400">
          Click to view full report
        </span>
      </div>
    </div>
  );
};
