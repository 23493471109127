import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { DocumentTypeEnum, getDocuments } from "~/api";

import DocumentCard from "~/components/DocumentCard";
import Pagination from "~/components/Pagination";
import SearchBox from "~/components/SearchBox";
import { Heading } from "~/components/ui/heading";
import { Paragraph } from "~/components/ui/paragraph";
import { Card } from "~/components/ui/card";
import UploadArea from "./Documents/UploadArea";

const Documents = () => {
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const navigate = useNavigate();

  const documentsQuery = useQuery({
    queryKey: ["referenceDocuments", page],
    queryFn: () =>
      getDocuments({ page: page, document_type: DocumentTypeEnum.Attachment }),
    refetchOnWindowFocus: false,
  });

  return (
    <div className=" custom grid grid-cols-2 gap-12">
      <div>
        <Heading as="h2">My Documents</Heading>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            navigate(`/documents/search?query=${query}&orgRefDocs=1`);
          }}
        >
          <div className="mb-2 mt-4">
            <SearchBox query={query} setQuery={setQuery} />
          </div>
        </form>
        {documentsQuery.data && documentsQuery.data.documents.length > 0 ? (
          <section>
            <Paragraph variant="small" className="my-5 text-muted-foreground">
              Showing {Math.min(documentsQuery.data.total, 5)} of{" "}
              {documentsQuery.data.total} Policies
            </Paragraph>
            {documentsQuery.data.documents.map((doc) => (
              <Link to={`/documents/${doc.id}`} key={doc.id}>
                <DocumentCard doc={doc} />
              </Link>
            ))}
            <Pagination
              setPage={(p) => setPage(p)}
              total={documentsQuery.data.total}
              page={page}
              perPage={5}
            />
          </section>
        ) : (
          <div className="text-sm text-gray-500">No results.</div>
        )}
      </div>
      <div className="flex flex-col gap-4">
        <Heading as="h2">Upload New Document(s)</Heading>
        <Card className="p-3">
          <UploadArea
            db_document_type={DocumentTypeEnum.Attachment}
            onUploadComplete={() => {
              console.log("Upload Complete");
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default Documents;
