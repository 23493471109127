import React, { useEffect, useMemo } from "react";

import { useChatContext } from "./chat/ChatContext";
import CustomChatMessageComponent from "./CustomChatMessageComponent";
import { openReferenceType } from "~/doc-util";
import { v4 as uuidv4 } from "uuid";
import { FunctionCall, MessagesProps } from "./chat/copilotkit-utils";

export interface CustomMessagesWithReferenceProps extends MessagesProps {
  openReference: openReferenceType;
  ratings: Record<string, number>;
  setRatings: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}

export const CustomMessagesWithReference = ({
  messages,
  inProgress,
  openReference,
  children,
  ratings,
  setRatings,
}: CustomMessagesWithReferenceProps) => {
  return (
    <CustomMessages
      messages={messages}
      inProgress={inProgress}
      openReference={openReference}
      children={children}
      ratings={ratings}
      setRatings={setRatings}
    />
  );
};

export const CustomMessages = ({
  messages,
  inProgress,
  openReference,
  children,
  ratings,
  setRatings,
}: MessagesProps & {
  openReference: openReferenceType;
  ratings: Record<string, number>;
  setRatings: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}) => {
  const context = useChatContext();

  const initialMessages = useMemo(
    () => makeInitialMessages(context.labels.initial),
    [context.labels.initial]
  );
  messages = [...initialMessages, ...messages];

  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "auto",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="copilotKitMessages overflow-y-auto">
      {messages.map((message, index) => {
        const isCurrentMessage = index === messages.length - 1;

        if (message.role === "user") {
          return (
            <div
              key={index}
              className="copilotKitMessage copilotKitUserMessage mt-4"
              style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}
            >
              {message.content}
            </div>
          );
        } else if (message.role == "assistant") {
          if (isCurrentMessage && inProgress && !message.content) {
            return (
              <div
                key={index}
                className={`copilotKitMessage copilotKitAssistantMessage`}
              >
                {context.icons.spinnerIcon}
              </div>
            );
          } else if (
            (!inProgress || index != messages.length - 1) &&
            !message.content &&
            message.function_call
          ) {
            return (
              <div
                key={index}
                className={`copilotKitMessage copilotKitAssistantMessage`}
              >
                {/*   done attribute has not been pushed to lastedd      {context.labels.done} */}
                {context.labels.title}
              </div>
            );
          }
          // TODO: Add back partial message
          // This shows up when the assistant is executing a function
          //
          // else if (message.status === "partial") {
          //   return (
          //     <div key={index} className={`copilotKitMessage copilotKitAssistantMessage`}>
          //       {context.labels.thinking} {context.icons.spinnerIcon}
          //     </div>
          //   );
          // }
          else {
            return (
              <div key={index} className="my-4 mr-6">
                <CustomChatMessageComponent
                  message={message}
                  openReference={openReference}
                  includeButtons={index !== 0}
                  allMessages={messages}
                  ratings={ratings}
                  setRatings={setRatings}
                />
              </div>
            );
          }
        }
        // TODO: Add back function and error messages
        //
        // else if (message.role === "function" && message.status === "success") {
        //   return (
        //     <div key={index} className={`copilotKitMessage copilotKitAssistantMessage`}>
        //       {context.labels.done}
        //     </div>
        //   );
        // } else if (message.status === "error") {
        //   return (
        //     <div key={index} className={`copilotKitMessage copilotKitAssistantMessage`}>
        //       {context.labels.error}
        //     </div>
        //   );
        // }
      })}
      {children}
      <div ref={messagesEndRef} />
    </div>
  );
};

function makeInitialMessages(initial?: string | string[]): Message[] {
  const initialArray: string[] = [];
  if (initial) {
    if (Array.isArray(initial)) {
      initialArray.push(...initial);
    } else {
      initialArray.push(initial);
    }
  }

  return initialArray.map((message) => ({
    id: uuidv4(),
    role: "assistant",
    content: message,
    isVisible: true,
  }));
}

export type Role = "system" | "user" | "assistant" | "function";

/**
 * Shared types between the API and UI packages.
 */
export interface Message {
  id: string;
  createdAt?: Date;
  content: string;
  ui?: string | null | undefined;
  role: Role;
  /**
   * If the message has a role of `function`, the `name` field is the name of the function.
   * Otherwise, the name field should not be set.
   */
  name?: string;
  /**
   * If the assistant role makes a function call, the `function_call` field
   * contains the function call name and arguments. Otherwise, the field should
   * not be set.
   */
  function_call?: FunctionCall;

  /**
   * Partial function call contains the function call name and arguments as they are
   * streamed from the model. This is used to display the function call in the UI.
   */
  partialFunctionCall?: FunctionCall;
  questionId?: string;
  isVisible: boolean;
  notContext?: boolean;
  chatId?: string;
}

/* interface FunctionDefinition {
  // The name of the function to be called. Must be a-z, A-Z, 0-9, or contain underscores and dashes, with a maximum length of 64.
  name: string;
  // The parameters the functions accepts, described as a JSON Schema object. See the [guide](/docs/guides/gpt/function-calling) for examples, and the [JSON Schema reference](https://json-schema.org/understanding-json-schema/) for documentation about the format.
  // To describe a function that accepts no parameters, provide the value `{"type": "object", "properties": {}}`.
  parameters: Record<string, unknown>;
  // A description of what the function does, used by the model to choose when and how to call the function.
  description?: string;
}
 */

export interface CopilotChatSuggestion {
  title: string;
  message: string;
  partial?: boolean;
  className?: string;
}

export type OnResponseCompleteCallback = (
  currentAIMessage: Message,
  currentUserMessage: Message
) => void;
