/**
 * v0 by Vercel.
 * @see https://v0.dev/t/bz8QpogGrc1
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import {
  DocumentTypeEnum,
  SingleDocResponseData,
} from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import AdditionalInformation from "~/components/AdditionalInformation";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { LoadingComponent } from "~/utils/util-report";
import { useState, useEffect } from "react";

export default function UCPolicyComparisonNew() {
  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    uploadedDocs,
    handleUploadComplete,
    handleDeleteUploadedDoc,
    validateDocumentTypes,
  } = useGenerateReportNew("policies_compare");

  const [validationState, setValidationState] = useState({
    hasEnoughFiles: false,
    typesSelected: false,
  });

  // Update validation state when documents change
  useEffect(() => {
    setValidationState({
      hasEnoughFiles: uploadedDocs.length >= 2,
      typesSelected: validateDocumentTypes(),
    });
  }, [uploadedDocs]);

  if (isLoading) {
    return <LoadingComponent title="GENERATING POLICY COMPARISON" />;
  }

  return (
    <StepReportComponent
      title="Generate Policy Comparison"
      lastStepName="Generate Policy Comparison"
      steps={[
        {
          number: 1,
          label: "Policy Documents",
          description: "Upload Policy Related Documents. Up to 1000 pages.",
          validate: () =>
            validationState.hasEnoughFiles && validationState.typesSelected,
          getValidationMessages: () => {
            const messages: string[] = [];
            if (!validationState.hasEnoughFiles) {
              messages.push("Please upload at least two documents");
            }

            if (!validationState.typesSelected) {
              const invalidDocs = uploadedDocs
                .filter(
                  (doc) =>
                    !doc.document.documentType ||
                    doc.document.documentType === DocumentTypeEnum.Reference
                )
                .map((doc) => doc.document.filename)
                .join(", \n ");

              if (invalidDocs) {
                messages.push(
                  `Please select valid document types for: ${invalidDocs}`
                );
              }
            }
            return messages;
          },
          component: (
            <PolicyDocumentsComponent
              uploadedDocs={uploadedDocs}
              handleUploadComplete={handleUploadComplete}
              handleDeleteUploadedDoc={handleDeleteUploadedDoc}
              onValidationChange={(validationType, isValid) => {
                setValidationState((prev) => ({
                  ...prev,
                  [validationType]: isValid,
                }));
              }}
            />
          ),
        },
        {
          number: 2,
          label: "Additional Information",
          description:
            "Please note that this text box is for additional context and will not change the format of the generated report. Ask questions using the Qumis Clerk once the report has generated.",
          validate: () => true, // Optional step, always valid
          component: (
            <AdditionalInformationComponent
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              handleUploadComplete={handleUploadComplete}
              handleDeleteUploadedDoc={handleDeleteUploadedDoc}
            />
          ),
        },
      ]}
      onFinalStep={handleGenerateReport}
      onCancelPath="/policy-comparison"
    />
  );
}

interface PolicyDocumentsProps {
  uploadedDocs: SingleDocResponseData[];
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
  onValidationChange: (validationType: string, isValid: boolean) => void;
}

const PolicyDocumentsComponent: React.FC<PolicyDocumentsProps> = ({
  uploadedDocs,
  handleUploadComplete,
  handleDeleteUploadedDoc,
  onValidationChange,
}) => {
  const validateFiles = () => {
    const hasEnoughFiles = uploadedDocs.length >= 2;
    const typesSelected = uploadedDocs.every(
      (doc) =>
        doc.document.documentType &&
        doc.document.documentType !== DocumentTypeEnum.Reference
    );

    onValidationChange("hasEnoughFiles", hasEnoughFiles);
    onValidationChange("typesSelected", typesSelected);
  };

  return (
    <div id="step1" className="flex w-full flex-col">
      <UploadArea
        showSearch={false}
        uploadedDocs={uploadedDocs}
        onUploadComplete={handleUploadComplete}
        onDeleteDocument={handleDeleteUploadedDoc}
        onValidationChange={onValidationChange}
        validateFiles={validateFiles}
      />
    </div>
  );
};

const AdditionalInformationComponent = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
  handleDeleteUploadedDoc,
}: {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
}) => {
  return (
    <AdditionalInformation
      additionalNotes={additionalNotes}
      setAdditionalNotes={setAdditionalNotes}
      handleUploadComplete={handleUploadComplete}
      handleDeleteUploadedDoc={handleDeleteUploadedDoc}
    />
  );
};
