import { Button } from "~/components/ui/button";
import { Link } from "react-router-dom";
import useSession from "~/hooks/useSession";
import UserDropdown from "~/components/Layout/Nav/UserDropdown";
import NavLink from "~/components/Layout/Nav/NavLink";

const LogInButton = () => (
  <Button asChild variant="secondary" className="my-3">
    <Link to="/log_in">Log In</Link>
  </Button>
);

const Nav = () => {
  const { loggedIn } = useSession();

  return (
    <nav className="flex items-center">
      <div className="mr-auto flex items-center">
        <Link to="/">
          <img
            src="/images/Qumis_FullColor_Logomark_RGB.png"
            alt="Qumis"
            className="my-1 mr-4 h-12 py-2"
            style={{ color: "white" }}
          />
        </Link>

        <Link to="/">
          <h2 className="mr-4 text-2xl font-bold text-black">Qumis</h2>
        </Link>

        {loggedIn && (
          <NavLink style={{ textDecoration: "none" }} to="/chat">
            {" "}
            Chat
          </NavLink>
        )}
        {loggedIn && <NavLink to="/policy-comparison">Comparison </NavLink>}
        {/*       {loggedIn && <NavLink to="/documents">Documents </NavLink>}  */}
        {/*        {loggedIn && <NavLink to="/reports">Generic Report </NavLink>} */}
        {loggedIn && <NavLink to="/claim-coverage">Claim</NavLink>}
        {/*       {loggedIn && <NavLink to="/analyze">Precedent</NavLink>} */}
        {loggedIn && <NavLink to="/generic-report">Custom</NavLink>}
        {loggedIn && <NavLink to="/coverage-checklist">Checklist</NavLink>}
      </div>
      {loggedIn ? <UserDropdown /> : <LogInButton />}
    </nav>
  );
};

export default Nav;
