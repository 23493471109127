import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import WelcomePopup from "~/components/WelcomePopup";
import useSession from "~/hooks/useSession";
import Cookies from "js-cookie";

const LandingPage = () => {
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [showReleaseUpdate, setShowReleaseUpdate] = useState(false);
  const { session } = useSession();

  useEffect(() => {
    if (session && session.email) {
      const hasSeenWelcome = sessionStorage.getItem("hasSeenWelcome");
      if (!hasSeenWelcome) {
        setShowWelcomePopup(true);
      }
    }
  }, [session]);

  useEffect(() => {
    const hasSeenRelease = Cookies.get("release_nov_2");
    if (!hasSeenRelease) {
      setShowReleaseUpdate(true);
    }
  }, []);

  const handleClosePopup = () => {
    setShowWelcomePopup(false);
    sessionStorage.setItem("hasSeenWelcome", "true");
  };

  const handleDismissRelease = () => {
    setShowReleaseUpdate(false);
    Cookies.set("release_nov_2", "true", { expires: 365 });
  };

  return (
    <div className="flex h-[85vh] flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <img
          src="/images/Qumis_FullColor_Logomark_RGB.png"
          alt="Qumis"
          className="my-1 h-16 py-1"
        />
        <h1 className="text-black-100 mb-20 text-3xl font-bold">
          How can I help you today ?
        </h1>
        <div className="mb-4 flex flex-row justify-center space-x-4">
          <NavLink
            to="/chat"
            className="inline-flex h-10 w-60 flex-1 items-center justify-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black ring-offset-background transition-colors hover:bg-black hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          >
            Policy Chat
          </NavLink>
          <NavLink
            to="/policy-comparison"
            className="inline-flex h-10 w-60 flex-1 items-center justify-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black ring-offset-background transition-colors hover:bg-black hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          >
            Policy Comparison
          </NavLink>
        </div>
        <div className="mb-4 flex flex-row justify-center space-x-4">
          <NavLink
            to="/claim-coverage"
            className="inline-flex h-10 w-60 flex-1 items-center justify-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black ring-offset-background transition-colors hover:bg-black hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          >
            Claim
          </NavLink>
          <NavLink
            to="/generic-report"
            className="inline-flex h-10 w-60 flex-1 items-center justify-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black ring-offset-background transition-colors hover:bg-black hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          >
            Custom Report
          </NavLink>
        </div>
        <div className="flex flex-row justify-center space-x-4">
          <div className="w-60 flex-1"></div>
          <div className="relative w-60 flex-1">
            <NavLink
              to="/coverage-checklist"
              className="inline-flex h-10 w-full items-center justify-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black ring-offset-background transition-colors hover:bg-black hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
            >
              Checklist
              <div className="ml-2">
                <img
                  src="/images/beta-100.png"
                  alt="Beta"
                  className="h-6 w-auto"
                />
              </div>
            </NavLink>
          </div>
          <div className="w-60 flex-1"></div>
        </div>
      </div>
      {showReleaseUpdate && (
        <div className="fixed bottom-4 left-4 right-4 mx-auto mb-1 max-w-4xl rounded-lg bg-blue-50 p-4 shadow-lg">
          <div className="flex items-start justify-between">
            <div>
              <h3 className="text-lg font-semibold">
                What's New - November 2nd Release
              </h3>
              <div className="mt-3 space-y-4 text-sm leading-relaxed">
                <div className="flex items-start space-x-3">
                  <span className="font-medium text-blue-600">✨</span>
                  <div>
                    <span className="font-medium">Enhanced Report Quality</span>
                    <p className="mt-1 text-gray-600">
                      We've heard your feedback about report quality and have
                      made significant improvements. You'll now see better, more
                      accurate results in all of your reports.
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-3">
                  <span className="font-medium text-green-600">🛠️</span>
                  <div>
                    <span className="font-medium">Text Selection Fixed</span>
                    <p className="mt-1 text-gray-600">
                      In the reports, you can now highlight text to access more
                      insights from your policy documents. This feature was
                      previously not working in Microsoft Edge.
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-3">
                  <span className="font-medium text-purple-600">📊</span>
                  <div>
                    <span className="font-medium">
                      Enhanced Policy Comparison Reports
                    </span>
                    <p className="mt-1 text-gray-600">
                      We've significantly improved our policy comparison reports
                      with more exhaustive comparison tables and analysis
                      written in a legal memo style.
                    </p>
                  </div>
                </div>

                {/*                 <div className="flex items-start space-x-3">
                  <span className="font-medium text-purple-600">📧</span>
                  <div>
                    <span className="font-medium">We Value Your Feedback</span>
                    <p className="mt-1 text-gray-600">
                      As always, we are extremely responsive to our users. If you have any feedback, suggestions, concerns, or criticism, please email support@qumis.com. We are here to help!
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            <button
              onClick={handleDismissRelease}
              className="ml-4 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
        </div>
      )}
      <WelcomePopup open={showWelcomePopup} onClose={handleClosePopup} />
    </div>
  );
};

export default LandingPage;
