import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DocumentTypeEnum,
  SingleDocResponseData,
} from "~/api/query_fns/documents";
import {
  ReportType,
  postCoverageAnalysis,
} from "~/api/query_fns/coverage-analysis";

const generateReportAnalysis = async (
  doc_ids: string,
  reportType: string,
  additionalNotes: string,
  reportTitle?: string,
  claimNumber?: string
) => {
  const { jobId, processingStatus } = await postCoverageAnalysis({
    doc_ids,
    additional_notes: reportTitle
      ? `REPORT TITLE: ${reportTitle}\n ${additionalNotes}`
      : additionalNotes,
    report_type: reportType,
    claim_number: claimNumber,
  });

  return { jobId, processingStatus };
};

export const useGenerateReportNew = (reportType: ReportType) => {
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [uploadedDocs, setUploadedDocs] = useState<SingleDocResponseData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [reportTitle, setReportTitle] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  const [stepValidation, setStepValidation] = useState<{
    [key: number]: boolean;
  }>({});

  // Reset state function
  const resetState = () => {
    setUploadedDocs([]); // Clear uploadedDocs
    setReportTitle("");
    setClaimNumber("");
    setAdditionalNotes("");
  };

  // Use useEffect to reset state when the component mounts
  useEffect(() => {
    resetState();
  }, []); // Empty dependency array ensures this runs only once

  const handleUploadComplete = (docResponse: SingleDocResponseData) => {
    setUploadedDocs((prevDocs) => {
      const exists = prevDocs.some(
        (doc) => doc.document.id === docResponse.document.id
      );
      if (!exists) {
        return [
          ...prevDocs,
          {
            ...docResponse,
            document: {
              ...docResponse.document,
              documentType: docResponse.document.documentType,
            },
          },
        ];
      }
      return prevDocs.map((doc) =>
        doc.document.id === docResponse.document.id
          ? {
              ...docResponse,
              document: {
                ...docResponse.document,
                documentType: docResponse.document.documentType,
              },
            }
          : doc
      );
    });
  };

  const handleDeleteUploadedDoc = (docId: string) => {
    setUploadedDocs((prevDocs) =>
      prevDocs.filter((doc) => doc.document.id !== docId)
    );
  };

  const handleGenerateReport = async () => {
    const docIds = uploadedDocs.map((doc) => doc.document.id).join("_");
    const { jobId } = await generateReportAnalysis(
      docIds,
      reportType,
      additionalNotes,
      reportTitle,
      claimNumber
    );
    setIsLoading(true);
    resetState();
    navigate(`/report/analysis/${jobId}`);
  };

  const areBothTypesUploaded = () => {
    return (
      uploadedDocs.some((doc) => doc.document.documentType === "fact") &&
      uploadedDocs.some((doc) => doc.document.documentType === "policy")
    );
  };

  const atleastOneDoc = () => {
    return uploadedDocs.length > 0;
  };

  const atleastOnePolicyAndAnotherDoc = () => {
    const policyDocs = uploadedDocs.filter(
      (doc) => doc.document.documentType === "policy"
    );
    return policyDocs.length >= 1 && uploadedDocs.length >= 2;
  };

  const isButtonDisabled = () => {
    return !(reportType === "policy_fact"
      ? areBothTypesUploaded()
      : reportType === "policies_compare"
      ? atleastOnePolicyAndAnotherDoc()
      : atleastOneDoc());
  };

  const validateStep = (stepNumber: number, isValid: boolean) => {
    setStepValidation((prev) => ({
      ...prev,
      [stepNumber]: isValid,
    }));
  };

  const isStepValid = (stepNumber: number) => {
    return stepValidation[stepNumber] ?? false;
  };

  // Validate document types are selected for all files
  const validateDocumentTypes = () => {
    console.log("uploadedDocs", uploadedDocs);
    return uploadedDocs.every(
      (doc) =>
        doc.document.documentType &&
        doc.document.documentType !== DocumentTypeEnum.Attachment
    );
  };

  return {
    isLoading,
    handleGenerateReport,
    isButtonDisabled,
    additionalNotes,
    setAdditionalNotes,
    uploadedDocs,
    handleUploadComplete,
    handleDeleteUploadedDoc,
    reportTitle,
    setReportTitle,
    claimNumber,
    setClaimNumber,
    validateStep,
    isStepValid,
    validateDocumentTypes,
  };
};

export const useDocumentUpload = () => {
  const [uploadedDocs, setUploadedDocs] = useState<SingleDocResponseData[]>([]);

  const handleUploadComplete = (docResponse: SingleDocResponseData) => {
    setUploadedDocs((prevDocs) => [...prevDocs, docResponse]);
  };

  const handleDeleteUploadedDoc = (docId: string) => {
    setUploadedDocs((prevDocs) =>
      prevDocs.filter((doc) => doc.document.id !== docId)
    );
  };

  return {
    uploadedDocs,
    setUploadedDocs,
    handleUploadComplete,
    handleDeleteUploadedDoc,
  };
};
