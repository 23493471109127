import { useChatContext } from "./ChatContext";

export interface ErrorProps {
  error?: string;
  onDismiss: () => void;
}

export const ChatErrorMessage = ({ error, onDismiss }: ErrorProps) => {
  const context = useChatContext();

  if (!error) return null;

  return (
    <div className="copilotKitError">
      <div className="copilotKitErrorContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-4 w-4"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" y1="8" x2="12" y2="12" strokeWidth="3" />
          <line x1="12" y1="16" x2="12.01" y2="16" strokeWidth="3" />
        </svg>
        <span>{error || context.labels.error}</span>
      </div>
      <button onClick={onDismiss} className="copilotKitErrorDismiss">
        ✕
      </button>
    </div>
  );
};
