import { SearchClient, SearchOptions } from "instantsearch.js/es/types";
import { searchTypesense } from "~/api/query_fns/typesense";

export function createTypesenseSearchClient(
  collectionName: string
): SearchClient {
  return {
    search: async (
      requests: { indexName: string; params: SearchOptions }[]
    ): Promise<any> => {
      try {
        const results = await Promise.all(
          requests.map(async (request) => {
            const response = await searchTypesense({
              collection_name: collectionName,
              search_params: {
                q: request.params?.query || "",
                query_by: request.params?.query,
                filter_by: request.params?.filters,
                facet_by: request.params?.facets?.join(","),
                page: request.params?.page,
                per_page: request.params?.hitsPerPage,
                ...request.params,
              },
            });

            return {
              ...response,
              index: request.indexName,
              hitsPerPage: request.params?.hitsPerPage,
              nbHits: response.found,
              nbPages: Math.ceil(
                response.found / (request.params?.hitsPerPage || 20)
              ),
              page: response.page,
              processingTimeMS: 0,
              query: request.params?.query || "",
              hits: response.hits.map((hit: any) => ({
                ...hit.document,
                objectID: hit.document.id,
                _highlightResult: hit.highlights,
              })),
            };
          })
        );

        return { results };
      } catch (error: any) {
        console.error("Error in Typesense search:", error);
        return { results: [] };
      }
    },
    searchForFacetValues: undefined,
  };
}
