import { useState } from "react";
import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
} from "~/components/ui/dialog";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { DocumentType } from "~/api/query_fns/documents";
import { destroyDocument } from "~/api";
import { useNavigate } from "react-router-dom";

const DeleteLink = ({ document }: { document: DocumentType }) => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleDelete = async () => {
    setSubmitting(true);
    await destroyDocument({ id: document.id });
    navigate("/documents");
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="cursor-pointer text-sm text-black underline underline-offset-4">
          Delete
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to remove this document?
          </DialogTitle>
          <DialogDescription>{document.filename}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogPrimitive.Close asChild>
            <Button variant="outline">Cancel</Button>
          </DialogPrimitive.Close>
          <Button
            variant="destructive"
            disabled={submitting}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteLink;
