import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { useChatContext } from "~/components/chat/ChatContext";
import useSpeechToText from "react-hook-speech-to-text";

import Mic from "~/components/Mic";
import { WandIcon } from "lucide-react";
import { cn } from "~/utils";

import { generateQueryExpansionsChatLLMService } from "~/api/query_fns/query-expansion";
import { TextSelectionMenuOption } from "./TextSelectionMenu";

interface InputProps {
  inProgress: boolean;
  onSend: (text: string) => void;
  children?: React.ReactNode;
  showWand?: boolean;
  chatContentContext: string;
  selectedTextMenuOption?: TextSelectionMenuOption;
  clearSelectedTextMenuOption?: () => void;
}

export const CustomChatInput = ({
  inProgress,
  onSend,
  showWand = true,
  chatContentContext,
  selectedTextMenuOption,
  clearSelectedTextMenuOption,
}: InputProps) => {
  const context = useChatContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [listening, setListening] = useState(false);
  const [previousText, setPreviousText] = useState("");
  const [localText, setLocalText] = useState("");
  const previousSelectedTextMenuOptionRef = useRef<
    TextSelectionMenuOption | undefined
  >();

  const [isWandActive, setIsWandActive] = useState(false);
  const [isDisabledInput, setDisabledInput] = useState(false);

  const { isRecording, stopSpeechToText } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
    crossBrowser: false,
  });

  // Add browser detection
  const isChrome = useMemo(() => {
    const browserInfo = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      vendor: navigator.vendor,
      language: navigator.language,
      cookieEnabled: navigator.cookieEnabled,
      onLine: navigator.onLine,
      hardwareConcurrency: navigator.hardwareConcurrency,
      maxTouchPoints: navigator.maxTouchPoints,
      screenResolution: {
        width: window.screen.width,
        height: window.screen.height,
        pixelRatio: window.devicePixelRatio,
      },
    };

    console.log("Browser Information:", browserInfo);

    const userAgent = navigator.userAgent.toLowerCase();
    return (
      userAgent.includes("chrome") &&
      !userAgent.includes("edge") &&
      !userAgent.includes("edg") &&
      !userAgent.includes("opera")
    );
  }, []);

  useEffect(() => {
    console.log(
      "useEffect triggered. selectedTextMenuOption:",
      selectedTextMenuOption,
      "localText:",
      localText
    );

    const previousOption = previousSelectedTextMenuOptionRef.current;

    if (
      selectedTextMenuOption?.formatedText &&
      (previousOption?.type !== "ADD_TO_CHAT" ||
        previousOption?.formatedText !== selectedTextMenuOption.formatedText)
    ) {
      console.log(
        "Updating localText with new formatted text:",
        selectedTextMenuOption.formatedText
      );
      setLocalText(selectedTextMenuOption.formatedText);

      if (localText && selectedTextMenuOption.autoDispatch) {
        console.log("Auto-dispatching message.");
        send();
        clearSelectedTextMenuOption && clearSelectedTextMenuOption();
      } else {
        console.log("Not auto-dispatching.");
      }
    } else {
      console.log(
        "No update needed: previous type was ADD_TO_CHAT with same formatted text, or no new formatted text."
      );
    }

    // Update the ref for the next render
    previousSelectedTextMenuOptionRef.current = selectedTextMenuOption;
  }, [selectedTextMenuOption, localText]);

  const handleDivClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) return;
    textareaRef.current?.focus();
  };

  const [inputPlaceholder, setInputPlaceholder] =
    useState("Ask a question ...");

  const onMicButtonPress = (placeholder: string) => {
    setInputPlaceholder(placeholder);
  };

  const send = () => {
    if (inProgress) return;
    if (localText.trim() === "") return;
    onSend(localText);
    clearSelectedTextMenuOption && clearSelectedTextMenuOption();
    setLocalText("");
    setListening(false);
    textareaRef.current?.focus();

    // Only call stopSpeechToText if we're currently recording
    if (isRecording) {
      stopSpeechToText();
    }
  };

  const onSpeechResult = (text: string) => {
    console.log("Received speech result:", text);
    if (text === previousText) return;

    console.log("Speech result:", text);
    setPreviousText(text);
    setLocalText(text.charAt(0).toUpperCase() + text.slice(1));
    if (text.trim().toLowerCase() === "go") {
      setLocalText("");
    }
    const lastWord = text.trim().split(" ").pop();
    if (lastWord?.toLowerCase() === "go") {
      console.log("Last word is 'go', sending message...");
      send();
      // Stop listening after sending the message
      //setListening(false);
      // Clear the text to prevent recursive sending
    }
  };

  const handleWandClick = async () => {
    // Toggle the wand state
    setIsWandActive((prevState) => !prevState);

    // Add your wand functionality here
    // console.log("Wand clicked, active:", !isWandActive);
    console.log("Wand clicked text:", localText);
    const currentText = localText;
    setLocalText("");
    setInputPlaceholder("Loading...");
    setDisabledInput(false);

    try {
      const response = await generateQueryExpansionsChatLLMService({
        user_request: currentText,
        context: chatContentContext,
      });
      if (response) {
        console.log("response", response);
        setLocalText(response.improved_request);
      }
    } catch (error) {
      setLocalText(currentText);
      console.error("Error generating query expansions:", error);
    }

    setInputPlaceholder("Ask a question ...");
    setDisabledInput(false);
    setIsWandActive((prevState) => !prevState);
  };

  const icon = inProgress ? context.icons.activityIcon : context.icons.sendIcon;
  const disabled = inProgress || localText.length === 0;

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    console.log("Text changed:", event.target.value);
    setLocalText(event.target.value);

    // Call clearSelectedTextMenuOption when all text is removed
    if (event.target.value === "" && clearSelectedTextMenuOption) {
      clearSelectedTextMenuOption();
    }
  };

  const memoizedTextarea = useMemo(
    () => (
      <AutoResizingTextarea
        ref={textareaRef}
        placeholder={inputPlaceholder}
        autoFocus={true}
        maxRows={10}
        value={localText}
        isDisabled={isDisabledInput}
        onChange={handleTextChange}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            send();
          }
        }}
        className="w-full"
      />
    ),
    [inputPlaceholder, localText, isDisabledInput, send]
  );

  return (
    <div>
      <div className="copilotKitInput flex flex-col" onClick={handleDivClick}>
        <div className="flex w-full items-center">
          <div className="flex items-center justify-start">
            {isChrome && (
              <Mic
                onMicButtonPress={onMicButtonPress}
                onSpeechResult={onSpeechResult}
                listening={listening}
                setListening={setListening}
              />
            )}
            {showWand && (
              <button
                onClick={handleWandClick}
                disabled={isWandActive}
                className={cn(
                  "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                  "h-10 w-10 p-0",
                  isWandActive
                    ? "border border-gray-300 text-black hover:bg-accent/60"
                    : "text-gray-500 hover:bg-accent hover:text-accent-foreground"
                )}
              >
                <WandIcon className="h-6 w-6" />
              </button>
            )}
          </div>
          <div className="relative mr-2 flex h-10 w-10 cursor-pointer items-center justify-center text-gray-500 transition-transform duration-300 ease-in-out hover:scale-105"></div>

          <div className="flex flex-grow items-center">{memoizedTextarea}</div>

          {/* <span>{children}</span> */}

          <button
            className="copilotKitSendButton"
            disabled={disabled}
            onClick={send}
          >
            {icon}
          </button>
        </div>
      </div>
      <div className="w-full text-center text-xs italic text-gray-500">
        *Use references provided to verify information.
      </div>
    </div>
  );
};

interface AutoResizingTextareaProps {
  maxRows?: number;
  placeholder?: string;
  value: string;
  isDisabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  className?: string;
}

const AutoResizingTextarea = forwardRef<
  HTMLTextAreaElement,
  AutoResizingTextareaProps
>(
  (
    {
      maxRows = 1,
      placeholder,
      value,
      isDisabled,
      onChange,
      onKeyDown,
      autoFocus,
      className,
    },
    ref
  ) => {
    const internalTextareaRef = useRef<HTMLTextAreaElement>(null);
    const [maxHeight, setMaxHeight] = useState<number>(0);

    useImperativeHandle(
      ref,
      () => internalTextareaRef.current as HTMLTextAreaElement
    );

    useEffect(() => {
      const calculateMaxHeight = () => {
        const textarea = internalTextareaRef.current;
        if (textarea) {
          textarea.style.height = "auto";
          const singleRowHeight = textarea.scrollHeight;
          setMaxHeight(singleRowHeight * maxRows);
          if (autoFocus) {
            textarea.focus();
          }
        }
      };

      calculateMaxHeight();
    }, [maxRows, autoFocus]);

    useEffect(() => {
      const textarea = internalTextareaRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${Math.min(
          textarea.scrollHeight,
          maxHeight
        )}px`;
      }
    }, [value, maxHeight]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event);
    };

    return (
      <div className="relative w-full">
        <textarea
          ref={internalTextareaRef}
          value={value}
          disabled={isDisabled || false}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          className={`w-full focus:outline-none focus:ring-0 ${
            className || ""
          }`}
          style={{
            overflow: "auto",
            resize: "none",
            maxHeight: `${maxHeight}px`,
          }}
          rows={1}
        />
      </div>
    );
  }
);

export default CustomChatInput;
