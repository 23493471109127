import { Heading } from "~/components/ui/heading";
import { Textarea } from "~/components/ui/textarea";
import UploadArea from "~/routes/Documents/UploadArea";
import {
  DocumentTypeEnum,
  SingleDocResponseData,
} from "~/api/query_fns/documents";

interface AdditionalInformationProps {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
  includeUploadHeading?: boolean;
  includeUploadArea?: boolean;
  placeholder?: string;
  availableDocumentTypes?: DocumentTypeEnum[];
  db_document_type?: DocumentTypeEnum;
}

const AdditionalInformation = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
  handleDeleteUploadedDoc,
  includeUploadHeading = false,
  includeUploadArea = false,
  placeholder = "Please describe any additional information relevant for the analysis.",
  availableDocumentTypes = [DocumentTypeEnum.Attachment],
  db_document_type = DocumentTypeEnum.Attachment,
}: AdditionalInformationProps) => {
  return (
    <div className="mb-10  w-full">
      {includeUploadHeading && (
        <Heading
          as="h2"
          className="text-black-100 flex flex-row items-center gap-2 p-1 "
        >
          Additional Information
        </Heading>
      )}
      {includeUploadArea && (
        <UploadArea
          db_document_type={db_document_type || DocumentTypeEnum.Attachment}
          onUploadComplete={handleUploadComplete}
          description="Upload related docs"
          onDeleteDocument={handleDeleteUploadedDoc}
          availableDocumentTypes={availableDocumentTypes}
        />
      )}
      <Textarea
        className="mt-2 text-black"
        placeholder={placeholder}
        value={additionalNotes}
        onChange={(e) => setAdditionalNotes(e.target.value)}
        rows={5}
      />
    </div>
  );
};

export default AdditionalInformation;
