import React, { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { FileText, Maximize2, Minimize2 } from "lucide-react";
import { Button } from "~/components/ui/button";
import { Card } from "~/components/ui/card";
import { processReferenceText } from "./CustomMarkdown";

export type ChartContextType = {
  reportId?: string;
  documentId?: string;
};

// Update the context creation
export const ChartContext = React.createContext<ChartContextType>({});

const CustomMarkdownTable: FC<{
  children?: React.ReactNode;
  openReference: (text: string) => void;
  selectedText?: string;
}> = ({ children, openReference, selectedText, ...props }) => {
  const [isFullScreen, setFullScreen] = React.useState(false);

  const processText = (text: unknown): React.ReactNode => {
    if (typeof text !== "string") {
      if (React.isValidElement(text)) {
        return React.cloneElement(text as React.ReactElement, {
          children: processText((text as React.ReactElement).props.children),
        });
      }
      return text as React.ReactNode;
    }

    const cleanText = text.replace(/<br\s*\/?>/gi, " ");
    return processReferenceText(cleanText, openReference, selectedText);
  };

  const processChildren = (
    children: React.ReactNode
  ): { headers: React.ReactNode[]; rows: React.ReactNode[][] } => {
    const headers: React.ReactNode[] = [];
    const rows: React.ReactNode[][] = [];
    let currentRow: React.ReactNode[] = [];

    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === "thead") {
          React.Children.forEach(
            (child as React.ReactElement).props.children,
            (tr) => {
              if (React.isValidElement(tr)) {
                React.Children.forEach(
                  (tr as React.ReactElement).props.children,
                  (th) => {
                    if (React.isValidElement(th)) {
                      headers.push(
                        processText((th as React.ReactElement).props.children)
                      );
                    }
                  }
                );
              }
            }
          );
        } else if (child.type === "tbody") {
          React.Children.forEach(
            (child as React.ReactElement).props.children,
            (tr) => {
              if (React.isValidElement(tr)) {
                currentRow = [];
                React.Children.forEach(
                  (tr as React.ReactElement).props.children,
                  (td) => {
                    if (React.isValidElement(td)) {
                      currentRow.push(
                        processText((td as React.ReactElement).props.children)
                      );
                    }
                  }
                );
                rows.push(currentRow);
              }
            }
          );
        }
      }
    });

    return { headers, rows };
  };

  const { headers, rows } = processChildren(children);

  const TableContent = () => (
    <Table {...props}>
      <TableHeader className="sticky top-0 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <TableRow className="border-b border-muted-foreground/10 transition-colors duration-200 hover:bg-muted/10">
          {headers.map((header, index) => (
            <TableHead key={index} className="w-1/6 px-6 py-4">
              <span className="font-semibold">{header}</span>
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {rows.map((row, rowIndex) => (
          <TableRow
            key={rowIndex}
            className="border-b border-muted-foreground/5 transition-colors duration-200 hover:bg-muted/10"
          >
            {row.map((cell, cellIndex) => (
              <TableCell key={cellIndex} className="px-6 py-4">
                <div className="flex items-center justify-between">
                  <span>{cell}</span>
                  {typeof cell === "string" && cell.match(/page\s*\d+/i) && (
                    <Button
                      variant="ghost"
                      size="sm"
                      className="mx-auto h-8 gap-1.5 rounded-full px-3 transition-all duration-200 hover:scale-105 hover:bg-background hover:shadow-sm"
                      onClick={() => {
                        const pageNum = cell.match(/\d+/)?.[0];
                        if (pageNum) openReference(`page ${pageNum}`);
                      }}
                    >
                      <FileText className="h-4 w-4 text-muted-foreground" />#
                      {cell.match(/\d+/)?.[0]}
                    </Button>
                  )}
                </div>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <>
      <Card
        className={`my-4 w-full  transition-shadow duration-200 ${
          isFullScreen ? "fixed inset-0 z-50" : ""
        }`}
      >
        <div
          className={`flex flex-grow flex-col overflow-hidden rounded-xl ${
            isFullScreen ? "h-screen" : ""
          }`}
        >
          <div className="flex justify-end p-1">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setFullScreen(!isFullScreen)}
              className="h-6 w-6 hover:bg-muted/10"
            >
              {isFullScreen ? (
                <Minimize2 className="h-4 w-4" />
              ) : (
                <Maximize2 className="h-4 w-4" />
              )}
            </Button>
          </div>
          <div className="flex-grow overflow-auto">
            <TableContent />
          </div>
        </div>
      </Card>
      {isFullScreen && (
        <div
          className="fixed inset-0 z-40 bg-black/50"
          onClick={() => setFullScreen(false)}
        />
      )}
    </>
  );
};

export default CustomMarkdownTable;
