import React, { ReactNode } from "react";

// Constants
export const INVALID_CATEGORIES = ["Category", "---"] as const;

export const STATUS_MAPPINGS = {
  Match: {
    label: "Match",
    color: "emerald",
    badgeClass: "bg-emerald-50 text-emerald-600 border-emerald-200",
    rowHoverClass: "hover:bg-emerald-50/50",
    gridClass: "bg-emerald-50 border-emerald-200",
  },
  Partial: {
    label: "Partial Match",
    color: "amber",
    badgeClass: "bg-amber-50 text-amber-600 border-amber-200",
    rowHoverClass: "hover:bg-amber-50/50",
    gridClass: "bg-amber-50 border-amber-200",
  },
  Mismatch: {
    label: "No Match",
    color: "red",
    badgeClass: "bg-red-50 text-red-600 border-red-200",
    rowHoverClass: "hover:bg-red-50/50",
    gridClass: "bg-red-50 border-red-200",
  },
  NA: {
    label: "Not Applicable",
    color: "slate",
    badgeClass: "bg-slate-50 text-slate-600 border-slate-200",
    rowHoverClass: "hover:bg-slate-50/50",
    gridClass: "bg-slate-50 border-slate-200",
  },
} as const;

// Type definitions
export type StatusType = keyof typeof STATUS_MAPPINGS | "total";

export interface CategoryCount {
  name: string;
  count: number;
}

// Utility functions
export const isValidCategory = (category: string): boolean => {
  return !INVALID_CATEGORIES.includes(
    category as (typeof INVALID_CATEGORIES)[number]
  );
};

export const getStatusFromElement = (element: React.ReactNode): StatusType => {
  if (!React.isValidElement(element)) return "NA";

  const key = element.key as string;
  if (!key) return "NA";

  const status = key.split("-")[0] as StatusType;
  return status in STATUS_MAPPINGS ? status : "NA";
};

export const getCategoryStatusCounts = (
  rows: React.ReactNode[][],
  category: string
): Record<StatusType, number> => {
  return rows.reduce((acc, row) => {
    const rowCategory = String(row[0]);

    if (!isValidCategory(rowCategory)) {
      return acc;
    }

    const isUncategorized =
      !rowCategory || rowCategory.trim() === "" || rowCategory === "undefined";
    const categoryMatches = isUncategorized
      ? category === "Un-Categorized"
      : rowCategory === category;

    if (categoryMatches) {
      const statusCell = row[row.length - 2];
      const status = getStatusFromElement(statusCell as ReactNode[]);
      acc[status] = (acc[status] || 0) + 1;
    }
    return acc;
  }, {} as Record<StatusType, number>);
};

export const getTotalStatusCounts = (
  rows: React.ReactNode[][]
): Record<StatusType, number> => {
  return rows.reduce((acc, row) => {
    const category = String(row[0]);

    if (!isValidCategory(category)) {
      return acc;
    }

    const statusCell = row[row.length - 2];
    const status = getStatusFromElement(statusCell as ReactNode[]);
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {} as Record<StatusType, number>);
};
