import { useState } from "react";
import { useQuery } from "react-query";

import { Link } from "react-router-dom";
import {
  ReportJob,
  ReportType,
  getReportsForUser,
} from "~/api/query_fns/coverage-analysis";
import Pagination from "~/components/Pagination";
import { Heading } from "~/components/ui/heading";
import { Paragraph } from "~/components/ui/paragraph";
import { CardHeader, CardTitle } from "~/components/ui/card";

import { format } from "date-fns";
import { Badge } from "~/components/ui/badge";

interface ExistingReportsProps {
  report_types: ReportType[];
  report_title: string;
}

const ExistingReports: React.FC<ExistingReportsProps> = ({
  report_types,
  report_title,
}) => {
  const [page, setPage] = useState<number>(1);

  const reportsQuery = useQuery({
    queryKey: ["policyDocuments", page],
    queryFn: () => getReportsForUser({ page: page, report_types }),
    refetchOnWindowFocus: false,
  });

  const hasReports =
    reportsQuery.data?.jobs && reportsQuery.data.jobs.length > 0;

  return (
    <div className="flex h-full flex-col space-y-4">
      <Heading
        as="h2"
        className="text-black-100 flex flex-row items-center gap-2 text-2xl font-semibold"
      >
        {report_title}
      </Heading>
      {hasReports && (
        <Paragraph variant="small" className="text-muted-foreground/75">
          Showing {Math.min(reportsQuery.data?.total ?? 0, 5)} of{" "}
          {reportsQuery.data?.total ?? 0}
        </Paragraph>
      )}

      <div className="relative flex-grow">
        {hasReports ? (
          <div className="absolute inset-0 grid gap-4 overflow-y-auto px-2">
            {reportsQuery.data?.jobs.map((report) => (
              <Link
                to={`/report/analysis/${report.jobId}`}
                key={report.jobId}
                className="block transform-gpu"
              >
                <ReportCard report={report} />
              </Link>
            ))}
          </div>
        ) : (
          <div className="flex h-full items-center justify-center">
            <Paragraph className="text-lg text-muted-foreground/90">
              No existing reports available.
            </Paragraph>
          </div>
        )}
      </div>

      {hasReports && (
        <div className="mt-auto pt-4">
          <Pagination
            setPage={(p) => setPage(p)}
            total={reportsQuery.data?.total ?? 0}
            page={page}
            perPage={5}
          />
        </div>
      )}
    </div>
  );
};

export default ExistingReports;

const ReportCard = ({ report }: { report: ReportJob }) => {
  const dateObj = new Date(report.createdAt);
  const formattedDate = format(dateObj, "MMM d, yyyy");

  return (
    <div className="group relative rounded-lg border border-border/60 bg-card p-5 shadow-[0_2px_8px_rgba(0,0,0,0.08)] transition-all duration-200 hover:scale-[1.002] hover:border-border hover:bg-accent/5 hover:shadow-[0_4px_12px_rgba(0,0,0,0.12)]">
      <CardHeader className="space-y-3 p-0">
        <div className="flex items-center justify-between">
          <CardTitle className="text-lg font-medium group-hover:text-primary">
            {report.reportName || "Report Name Empty"}
          </CardTitle>
          <span className="text-sm text-muted-foreground">{formattedDate}</span>
        </div>

        <div className="flex flex-wrap gap-1.5">
          {[
            report.claimNumber && (
              <Badge
                key="claimNumber"
                variant="primary"
                className="bg-primary/90 text-primary-foreground"
              >
                Claim #{report.claimNumber}
              </Badge>
            ),
            ...report.documents.map((rep) => (
              <Badge
                key={rep.id}
                variant="secondary"
                className="bg-secondary/80 text-secondary-foreground"
              >
                {rep.filename}
              </Badge>
            )),
          ].filter(Boolean)}
        </div>
      </CardHeader>
    </div>
  );
};
