import { Worker } from "@react-pdf-viewer/core";
import { useQuery } from "react-query";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { getDocument } from "~/api";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";
import { Heading } from "~/components/ui/heading";
import { CopilotKit, useCopilotReadable } from "@copilotkit/react-core";
import { useOpenReferenceDetails } from "../doc-util"; // Add this import
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { CustomChat } from "~/components/util-chat";
import React from "react";
import { defer, Await } from "react-router-dom";
import { LoadingComponent } from "~/utils/util-report";
import ErrorBoundaryReport from "~/components/ErrorBoundaryReport";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { fetchDocumentTexts } from "~/utils";
import { generateChatContext } from "~/utils/util-report"; // Add this import
import { Citation } from "~/api/query_fns/coverage-analysis";

interface LoaderParams {
  params: {
    id?: string;
  };
}

type genPolicyChatLoaderResponse = SingleDocResponseData & {
  oId: string;
};

export const loader = async ({ params }: LoaderParams) => {
  const fetchPolicyData = async (): Promise<genPolicyChatLoaderResponse> => {
    let retryCount = 0;
    const maxRetries = 20;
    const retryDelay = 30000;

    while (retryCount < maxRetries) {
      const doc = await getDocument({ id: params.id || "", includes: "text" });
      await fetchDocumentTexts([doc]);
      if (doc.document.text) {
        return {
          ...doc,
          oId: doc.document.oId || "",
        };
      }
      console.log(
        `Text is not available for doc id: ${doc.document.id}, filename: ${doc.document.filename}`
      );
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      retryCount++;
    }
    throw new Error(
      `OCR processing has failed. Try again or contact support@qumis.ai`
    );
  };

  return defer({ policyData: fetchPolicyData() });
};

const getSelection = () => {
  if (window.getSelection) {
    return window.getSelection() || null;
  }

  if (document.getSelection) {
    return document.getSelection() || null;
  }
  return null;
};

const GenPolicyChat = () => {
  const { policyData } = useLoaderData() as {
    policyData: Promise<genPolicyChatLoaderResponse>;
  };

  return (
    <React.Suspense
      fallback={
        <LoadingComponent title="Loading Policy" showProgressBar={true} />
      }
    >
      <Await resolve={policyData} errorElement={<ErrorBoundaryReport />}>
        {(loadedData: genPolicyChatLoaderResponse) => (
          <PolicyChatContent data={loadedData} />
        )}
      </Await>
    </React.Suspense>
  );
};

const PolicyChatContent = ({ data }: { data: genPolicyChatLoaderResponse }) => {
  const { presignedUrl, document: currentDocument, oId } = data;
  const [citations] = useState<Citation[]>([]);

  const userId = data.document.upId;

  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
    citation,
    addCitations,
  } = useOpenReferenceDetails([data], citations);

  const [searchParams] = useSearchParams();
  const [highlighted, setHighlighted] = useState<null | string>(null);

  const [activeSearchResultDoc] = useState<{
    id: string;
    page: number;
    highlight?: string;
    matchType?: string;
  } | null>(null);
  const viewingActiveSearchResultDoc = !!activeSearchResultDoc;

  const searchResultDocQuery = useQuery({
    queryKey: ["document", activeSearchResultDoc?.id],
    queryFn: () => getDocument({ id: activeSearchResultDoc?.id as string }),
    enabled: !!activeSearchResultDoc,
    refetchOnWindowFocus: false,
  });

  const setHighlightedContent = () => {
    const highlightEvent = getSelection();
    const highlightedContent =
      highlightEvent?.toString()?.replace(/(\r\n|\n|\r)/gm, "") || null;
    setHighlighted(highlightedContent);
  };

  const isSearchResultDoc = !!searchResultDocQuery?.data?.document;
  const context = generateChatContext([currentDocument], "");
  const gotoPage = searchParams.get("page");

  useCopilotReadable({
    description: "all documentations",
    value: context,
  });

  return (
    <div
      id="policy-chat-container"
      className="mx-auto flex w-full max-w-[100vw] flex-col gap-4 lg:flex-row"
    >
      <div id="document-container" className="mt-8 h-full w-full lg:w-[60vw]">
        <PDFViewerDialog
          open={isDocViewerOpen}
          doc={document}
          initialPage={initialPage}
          setDocViewerState={setDocViewerState}
          citation={citation}
        />

        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div className="custom-shadcn-components mb-2">
            <div className="custom-heading flex flex-row items-center gap-2 p-4">
              <Heading variant="h1" className="text-black-100 mt-1 break-words">
                {isSearchResultDoc
                  ? searchResultDocQuery?.data?.document.filename
                  : currentDocument.filename}
              </Heading>
            </div>
          </div>

          <div className="custom-shadcn-components mx-auto h-[75vh] w-full overflow-y-auto">
            <MainDocument
              presignedUrl={presignedUrl}
              persistHighlight={setHighlightedContent}
              hideDocument={viewingActiveSearchResultDoc}
              highlightedContent={activeSearchResultDoc ? "" : highlighted}
              initialPage={gotoPage ? parseInt(gotoPage, 10) : undefined}
            />
          </div>
        </Worker>
      </div>

      <div
        id="chat-container"
        className="flex h-[calc(100vh-4rem)] w-full flex-col overflow-hidden lg:w-[40vw]"
      >
        <CopilotKit url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}>
          <CustomChat
            context={context}
            initialMessage={`Hi 👋 - If you have any further questions about this policy, please let us know below.
              \n For example, you might want to ask:
              \n - What are the coverages of this policy?
              \n - What are the endorsements of this policy?
              \n - Does this policy have a professional liability exclusion
              \n - What are the premiums of this policy
              \n - Are there any notification restrictions?
              \n - Are there any exclusions in this policy related to contractual liability for media
            `}
            documents={[data]}
            reportKey={`${currentDocument.id}_policy_question`}
            openReference={openReference}
            reportSource="auth_ui_policy"
            oId={oId}
            addCitations={addCitations}
            userId={userId}
          />
        </CopilotKit>
      </div>
    </div>
  );
};

export default GenPolicyChat;
