import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { SingleDocResponseData } from "./api/query_fns/documents";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function fetchDocumentTexts(documents: SingleDocResponseData[]) {
  if (!Array.isArray(documents)) return;

  await Promise.all(
    documents.map(async (doc) => {
      if (doc.document?.textUrl) {
        const textResponse = await fetch(doc.document.textUrl);
        doc.document.text = await textResponse.text();
      }
    })
  );
}

export const generateHash = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        if (!event.target || !event.target.result) {
          throw new Error("Failed to read file");
        }
        const buffer = event.target.result as ArrayBuffer;
        const hashBuffer = await window.crypto.subtle.digest("SHA-256", buffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, "0"))
          .join("");
        resolve(hashHex);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(new Error("Error reading file"));
    reader.readAsArrayBuffer(file);
  });
};
